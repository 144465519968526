import {
  Flex, Heading,
  HStack,
  Image,
  Container, Text,
  VStack, useColorModeValue,
} from '@chakra-ui/react'
import React from 'react';
import Marquee from "react-fast-marquee";

import wbiccLogoWhite from '../../assets/mosques/wbicc_white.png';
import wbiccLogoColor from '../../assets/mosques/wbicc_color.png';
import elmLogo from '../../assets/mosques/elm.png';
import ejmLogo from '../../assets/mosques/ejm.png';
import ncmLogo from '../../assets/mosques/ncm.png';
import halalmunchLogo from '../../assets/halalmunch_logo.png';

function LandingPartnership() {
  return (
    <Flex flex={1} align="end" py={20} width="100%" flexDir={'column'}
          bg={useColorModeValue('gray.50', 'gray.900')} overflow={'hidden'}
    >
      <Container maxW="5xl" pb={'50px'} id="pricing">
        <VStack spacing={2} textAlign="center">
          <Heading as="h1" size="2xl">
            Supported Mosques
          </Heading>
          <Text fontSize={'xl'}>
            We're adding more Mosques every month, keep an eye out!
          </Text>
        </VStack>
      </Container>
      <Marquee gradient={false}>

        <HStack spacing={[4, 6, 20]}>
          <Image src={elmLogo} height={'100px'}/>
          <Image src={null} width={'30px'}/>
          <Image src={ncmLogo} height={'100px'}/>
          <Image src={null} width={'30px'}/>
          <Image src={wbiccLogoColor} height={'100px'}/>
          <Image src={null} width={'30px'}/>
          <Image src={ejmLogo} height={'100px'}/>
          <Image src={null} width={'30px'}/>
        </HStack>
      </Marquee>
    </Flex>
  );
}

export default LandingPartnership;
