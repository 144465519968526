import {
  Box,
  Container,
  Heading, HStack, Image, Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import React from 'react';
import AppIncomingCall from '../../assets/app_screenshots/AppIncomingCall.png'
import AppCallAnsweredPageLight
  from '../../assets/app_screenshots/AppCallAnsweredPageLight.png'
import AppCallAnsweredPageDark
  from '../../assets/app_screenshots/AppCallAnsweredPageDark.png'
import AppMainPageLight from '../../assets/app_screenshots/AppMainPageLight.png'
import AppMainPageDark from '../../assets/app_screenshots/AppMainPageDark.png'
import AppSettingsPrefsLight
  from '../../assets/app_screenshots/AppSettingsPrefsLight.png'
import AppSettingsPrefsDark
  from '../../assets/app_screenshots/AppSettingsPrefsDark.png'
import AppSettingsCallLight
  from '../../assets/app_screenshots/AppSettingsCallLight.png'
import AppSettingsCallDark
  from '../../assets/app_screenshots/AppSettingsCallDark.png'
import AppAdhkarLight from '../../assets/app_screenshots/AppAdhkarLight.png'
import AppAdhkarDark from '../../assets/app_screenshots/AppAdhkarDark.png'
import AppAdhkarEndLight from '../../assets/app_screenshots/AppAdhkarEndLight.png'
import AppAdhkarEndDark from '../../assets/app_screenshots/AppAdhkarEndDark.png'
import AppPrayAtHomeLight from '../../assets/app_screenshots/AppPrayAtHomeLight.png'
import AppPrayAtHomeDark from '../../assets/app_screenshots/AppPrayAtHomeDark.png'
import AppPrayAtMosqueLight from '../../assets/app_screenshots/AppPrayAtMosqueLight.png'
import AppPrayAtMosqueDark from '../../assets/app_screenshots/AppPrayAtMosqueDark.png'

function LandingFeatures() {
  return (
    <VStack spacing={10}
      // display={['flex', 'flex', 'none']}

    >

      <VStack spacing={6} padding={5} maxWidth={'900px'}>
        <Text fontWeight={'bold'} fontSize={'1.7em'}>Daily Fajr Calls</Text>
        <Stack direction={['column', 'column', 'row']} spacing={10} align={'center'}>
          <HStack justify={'center'} spacing={10} flex={1} minWidth={['100%', '100%', '50%']}>
            <Image src={AppIncomingCall} maxWidth={['150px', '200px', '170px']}/>
            <Image src={useColorModeValue(AppCallAnsweredPageLight, AppCallAnsweredPageDark)} maxWidth={['150px', '200px', '170px']}/>
          </HStack>
          <Text fontSize={'1.2em'}>
            You'll no longer have to worry about setting alarms before bed.
            <br/><br/>You need to relax before bed, so we do all the hard work for you and call you when it's time for Fajr.
            <br/><br/>Don't worry your number isn't shared with anyone, our Muslim bots will call you.
          </Text>
        </Stack>
      </VStack>

      <VStack spacing={6} padding={5} maxWidth={'900px'}>
        <Text fontWeight={'bold'} fontSize={'1.7em'}>Pray at Home or at Mosque</Text>
        <Stack direction={['column', 'column', 'row-reverse']} spacing={10} align={'center'}>
          <HStack justify={'center'} spacing={10} flex={1} minWidth={['100%', '100%', '50%']}>
            <Image src={useColorModeValue(AppPrayAtHomeLight, AppPrayAtHomeDark)} maxWidth={['150px', '200px', '170px']}/>
            <Image src={useColorModeValue(AppPrayAtMosqueLight, AppPrayAtMosqueDark)} maxWidth={['150px', '200px', '170px']}/>
          </HStack>
          <Text fontSize={'1.2em'}>
            You can select Fajr time based on your local Mosque, if you'd like to <b>Pray At Mosque</b>.
            <br/><br/>Or you can continue praying at your custom Fajr time if you'd like to <b>Pray At Home</b>.
          </Text>
        </Stack>
      </VStack>

      <VStack spacing={6} padding={5} maxWidth={'900px'}>
        <Text fontWeight={'bold'} fontSize={'1.7em'}>Fajr Stats</Text>
        <Stack direction={['column', 'column', 'row']} spacing={10} align={'center'}>
          <HStack justify={'center'} spacing={10} flex={1} minWidth={['100%', '100%', '50%']}>
            <Image src={useColorModeValue(AppMainPageLight, AppMainPageDark)} maxWidth={['150px', '200px', '170px']}/>
          </HStack>
          <Text fontSize={'1.2em'}>
            We'll track how often you answer our calls, let's try and get more answered calls than missed calls.
            <br/><br/>Answer enough calls in a row and you'll start earing a Fajr streak!
          </Text>
        </Stack>
      </VStack>

      <VStack spacing={6} padding={5} maxWidth={'900px'}>
        <Text fontWeight={'bold'} fontSize={'1.7em'}>Fajr Call Preferences</Text>
        <Stack direction={['column', 'column', 'row-reverse']} spacing={10} align={'center'}>
          <HStack justify={'center'} spacing={10} flex={1} minWidth={['100%', '100%', '50%']}>
            <Image src={useColorModeValue(AppSettingsPrefsLight, AppSettingsPrefsDark)} maxWidth={['150px', '200px', '170px']}/>
            <Image src={useColorModeValue(AppSettingsCallLight, AppSettingsCallDark)} maxWidth={['150px', '200px', '170px']}/>
          </HStack>
          <Text fontSize={'1.2em'}>
            Customise when we call you for Fajr at whatever time suits you best.
            <br/><br/>Easily toggle off Fajr calls if you aren't praying.
          </Text>
        </Stack>
      </VStack>

      <VStack spacing={6} padding={5} maxWidth={'900px'}>
        <Text fontWeight={'bold'} fontSize={'1.7em'}>Morning Adhkar</Text>
        <Stack direction={['column', 'column', 'row']} spacing={10} align={'center'}>
          <HStack justify={'center'} spacing={10} flex={1} minWidth={['100%', '100%', '50%']}>
            <Image src={useColorModeValue(AppAdhkarLight, AppAdhkarDark)} maxWidth={['150px', '200px', '170px']}/>
            <Image src={useColorModeValue(AppAdhkarEndLight, AppAdhkarEndDark)} maxWidth={['150px', '200px', '170px']}/>
          </HStack>
          <Text fontSize={'1.2em'}>
            Morning Adhkar available straight in our app.
            <br/><br/>We've got a list of all your morning adhkar. With a beautiful UI
            you can scroll through at your own pace.
          </Text>
        </Stack>
      </VStack>

    </VStack>
  );
}

export default LandingFeatures;
