import Card from './Card'
import React, { useContext, useState } from 'react'
import {
  Flex,
  Heading, Text,
  VStack,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader, ModalCloseButton, ModalBody, ModalFooter
} from '@chakra-ui/react'

import { AppContext } from '../providers/AppProvider'
import ReactGA from 'react-ga4'

function DeleteAccountCard () {

  const [isLoading, setIsLoading] = useState(false)
  const [password, setPassword] = useState(null)
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)

  const { deleteAccount, deleteFajrTimeSchedule, fetchCurrentUser, showSuccessToast, showErrorToast, user } = useContext(
    AppContext)

  function onDelete () {
    setShowDeleteConfirmModal(true)
  }

  function onDeleteConfirm () {
    setIsLoading(true)
    deleteAccount(password)
      .then(response => {
        showSuccessToast({message: response?.data?.result?.message})
      setShowDeleteConfirmModal(false)
      fetchCurrentUser();
    })
      .catch(e => showErrorToast({message: e.message}))
      .finally(_ => setIsLoading(false))

    ReactGA.event({
      action: 'Clicked Delete Account'
    })
  }

  return (
    <>
      <Card>
        <VStack width={'100%'} spacing={6} align={'start'}>
          <Flex justify={'space-between'} align={'center'} width={'100%'}>
            <Heading as={'h3'} size={'md'}>Delete your account</Heading>
          </Flex>

          <Text align={"start"} fontSize={"sm"} color={"gray.500"}>
            Permanently delete your account, once deleted your account cannot be recovered.
            Any existing memberships will be revoked.
          </Text>

          <Button size={'sm'} bg={"red.500"} color={"white"} onClick={onDelete}>Delete account</Button>
        </VStack>
      </Card>


      <Modal
        isOpen={showDeleteConfirmModal}
        onClose={() => setShowDeleteConfirmModal(false)}
      >
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Are you sure you want to delete your account?</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <VStack>
            <Text align={"start"} fontSize={"sm"} color={"gray.400"}>
              Once deleted your account cannot be recovered.
              Any existing memberships will be revoked.
            </Text>
            <FormControl>
              <FormLabel htmlFor={"password"}>Confirm Password</FormLabel>
              <Input id={'password'} type={"password"} placeholder={"********"} onChange={(event) => setPassword(event.target.value)}/>
            </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setShowDeleteConfirmModal(false)}>Cancel</Button>
            <Button bg={"red.500"} color={"white"} onClick={onDeleteConfirm} isLoading={isLoading}>Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DeleteAccountCard
