import Card from './Card'
import React, { useContext, useEffect, useState } from 'react'
import {
  Flex,
  Heading,
  VStack,
  HStack,
  Spinner,
  Box,
  Button,
  Text,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  PinInput,
  PinInputField,
  Tag,
  DrawerFooter, Drawer, TableContainer, Table, Thead, Tr, Th, Tbody, Td,
} from '@chakra-ui/react'
import { PhoneIcon, MoonIcon, CheckCircleIcon, TimeIcon } from '@chakra-ui/icons'
import { FaCircle, FaRegCircle } from 'react-icons/fa'
import { AppContext } from '../providers/AppProvider'
import dayjs from 'dayjs'
import ErrorBoundary from './ErrorBoundary'

let weeks = []
for (var i=0; i<26; i++) {
  weeks.push(i)
}

function CallsStatsCard () {

  const [isLoadingStats, setIsLoadingStats] = useState(true)
  const [stats, setStats] = useState(null)
  const [showLogs, setShowLogs] = useState(false)

  const { fetchCallsStats, showErrorToast, user } = useContext(AppContext)

  useEffect(() => {
    if (user != null) {
      fetchStats()
    }
  }, [user])

  function fetchStats () {
    setIsLoadingStats(true)
    fetchCallsStats().
      then(response => setStats(response?.data?.result)).
      catch(e => showErrorToast({ message: e.message })).
      finally(_ => setIsLoadingStats(false))
  }

  return (
    <>
    <Card>
      <VStack width={'100%'} spacing={6} align={'start'}>
        <Flex width={'100%'} justify={'space-between'} align={'center'}>
          <Heading as={'h3'} size={'md'}>Monthly Stats</Heading>
          <Button size={'sm'} onClick={fetchStats}>Refresh</Button>
        </Flex>

        <Box display={isLoadingStats ? 'flex' : 'none'} align={'center'} justify={"center"}>
          <Spinner color={'teal.500'}/>
        </Box>


        <Box display={isLoadingStats ? 'none' : 'flex'} flex={1} width={"100%"}>
          <VStack flex={1} flexDir={"column"} width={'100%'} spacing={6}>
            <StatGroup width={"100%"}>

              {/*<Stat>*/}
              {/*  <StatLabel>Fajr Calls</StatLabel>*/}
              {/*  <HStack justify={"center"}>*/}
              {/*    <PhoneIcon/>*/}
              {/*  <StatNumber>{stats?.total || 0}</StatNumber>*/}
              {/*  </HStack>*/}
              {/*</Stat>*/}
              <Stat>
                <StatLabel>Fajr Calls Answered</StatLabel>
                <HStack justify={"center"}>
                  <CheckCircleIcon color={"green.500"}/>
                  <StatNumber>{stats?.answered || 0}</StatNumber>
                </HStack>
              </Stat>

              <Stat>
                <StatLabel>Fajr Calls Missed</StatLabel>

                <HStack justify={"center"}>
                  <MoonIcon color={"yellow.500"}/>
                  <StatNumber>{stats?.missed || 0}</StatNumber>
                </HStack>
              </Stat>
            </StatGroup>

            {/*<HStack flex={1} justify={"center"} display={stats?.answered === stats?.total ? 'flex' : 'none'}>*/}
            {/*  <CheckCircleIcon color={"green.500"}/>*/}
            {/*  <Text fontWeight={"bold"}>You've not missed any! Alhamdullilah!</Text>*/}
            {/*</HStack>*/}

            {/*<HStack flex={1} justify={"center"} display={stats?.answered / stats?.total < 0.6 ? 'flex' : 'none'}>*/}
            {/*  <CheckCircleIcon color={"green.500"}/>*/}
            {/*  <Text fontWeight={"bold"}>You've not missed any! Alhamdullilah!</Text>*/}
            {/*</HStack>*/}

            {/*<HStack spacing={2}>*/}
            {/*  <VStack spacing={0}>*/}
            {/*    <Text fontSize={"xs"} color={"gray.500"}>SUN</Text>*/}
            {/*    <Text fontSize={"xs"} color={"gray.500"}>MON</Text>*/}
            {/*    <Text fontSize={"xs"} color={"gray.500"}>TUE</Text>*/}
            {/*    <Text fontSize={"xs"} color={"gray.500"}>WED</Text>*/}
            {/*    <Text fontSize={"xs"} color={"gray.500"}>THU</Text>*/}
            {/*    <Text fontSize={"xs"} color={"gray.500"}>FRI</Text>*/}
            {/*    <Text fontSize={"xs"} color={"gray.500"}>SAT</Text>*/}
            {/*  </VStack>*/}
            {/*  {Object.entries(stats?.heatmap || {})?.map((heatEntry, i) =>*/}
            {/*    <VStack spacing={0}>*/}
            {/*      <StatCircle isColor={heatEntry[1][0]} />*/}
            {/*      <StatCircle isColor={heatEntry[1][1]} />*/}
            {/*      <StatCircle isColor={heatEntry[1][2]} />*/}
            {/*      <StatCircle isColor={heatEntry[1][3]} />*/}
            {/*      <StatCircle isColor={heatEntry[1][4]} />*/}
            {/*      <StatCircle isColor={heatEntry[1][5]} />*/}
            {/*      <StatCircle isColor={heatEntry[1][6]} />*/}
            {/*    </VStack>*/}
            {/*  )}*/}
            {/*</HStack>*/}

            <Button  onClick={() => setShowLogs(true)}>Show Call Logs</Button>
          </VStack>
        </Box>

      </VStack>
    </Card>
      <Drawer
        isOpen={showLogs}
        placement="bottom"
        onClose={() => setShowLogs(false)}
        style={{height: '100%'}}
      >
        <DrawerOverlay/>
        <DrawerContent style={{height: '100%'}}>
          <DrawerCloseButton/>
          <DrawerHeader>Call Logs</DrawerHeader>

          <DrawerBody padding={0}>

            <ErrorBoundary>

              <Flex justify={'space-between'} align={'center'}>
                <HStack>
                  {/*<Button size={'sm'} onClick={fetchFajrTimes}>Refresh</Button>*/}
                  {/*<Button size={'sm'} onClick={onClearCallScheulde}>Clear Schedule</Button>*/}
                </HStack>
              </Flex>

              <TableContainer width={'100%'}>
                <Table size={'sm'}>
                  <Thead>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Duration</Th>
                      {/*<Th>Status</Th>*/}
                      <Th>Time</Th>
                      <Th>Answered By</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {stats?.callLogs?.map(callLog => {
                        return (
                          <Tr key={callLog.callTimestamp}>
                            <Td>{callLog.callDate}</Td>
                            <Td>{callLog.callDuration || 0} s</Td>
                            {/*<Td>{callLog.callStatus}</Td>*/}
                            <Td>{callLog.callTimestamp ? dayjs(callLog.callTimestamp).format('HH:mm A') : null}</Td>
                            <Td><Tag size={'sm'} colorScheme={getColorSchemeForCallLog(callLog)}>{callLog.callAnsweredBy}</Tag></Td>
                          </Tr>)
                      },
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </ErrorBoundary>

          </DrawerBody>

          <DrawerFooter>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

const getColorSchemeForCallLog = (callLog) => {
  if (callLog.callAnsweredBy === 'Answered') {
    return 'green';
  } else {
    return 'red';
  }
}

const StatCircle = ({ isColor }) => {
  const color = useColorModeValue("gray.200", 'gray.800')

  return (
    <>
      <Text display={isColor ? 'block' : 'none'}  fontSize={"xs"} color={"green.500"}>
        <Icon boxSize={"7px"} as={FaCircle}/>
      </Text>

      <Text display={isColor ? 'none' : 'block'} fontSize={"xs"} color={color}>
        <Icon boxSize={"7px"} as={FaCircle}/>
      </Text>
    </>
  )
}

export default CallsStatsCard
