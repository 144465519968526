import React, { useContext } from 'react'
import {
  Stack,
  Link,
  Heading,
  Text,
  VStack,
  List,
  ListItem,
  ListIcon,
  Button,
  Container,
  Tag,
  Skeleton, SkeletonText
} from '@chakra-ui/react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Card from './Card';
import { AppContext } from '../providers/AppProvider'
import { getCurrency } from 'locale-currency';

export const currencyFormatter = new Intl.NumberFormat({}, {
  style: 'currency', currency: 'GBP',
});

export const premiumPlans = {
  TIER1: {
    label: 'BASIC',
    price: 0.99,
    desc: 'Help me get started with waking up for Fajr!',
    bgGradient: 'linear(to-l, teal, teal)',
    priceFormatted: currencyFormatter.format(0.99),
  },
  TIER2: {
    label: 'PLUS',
    price: 3.99,
    desc: "I don't want to miss Fajr, sometimes Tahajjud too!",
    priceFormatted: currencyFormatter.format(3.99),
    bgGradient: 'linear(to-l, blue.400, teal.400)',
    tag: <Tag bgGradient="linear(to-l, blue.400, teal.400)">PLUS</Tag>,
    upgradeMessage: 'Upgrade to PLUS plan to access this feature',
  },
  TIER3: {
    label: 'PREMIUM',
    price: 5.99,
    desc: "I never want to miss Fajr, and don't want my friends too either!",
    priceFormatted: currencyFormatter.format(5.99),
    bgGradient: 'linear(to-l, purple.400, pink.400)',
    tag: <Tag bgGradient="linear(to-l, purple.400, pink.400)">PREMIUM</Tag>,
    upgradeMessage: 'Upgrade to PREMIUM plan to access this feature',
  },
};

export const features = [
  {
    label: 'Daily Fajr wake up calls',
    isPlus: false,
    isPremium: false,
    isComingSoon: false,
  },
  {
    label: 'Local Mosque Fajr timings',
    isPlus: false,
    isPremium: false,
    isComingSoon: false,
  },
  {
    label: 'Automatic Fajr time scheduling',
    isPlus: false,
    isPremium: false,
    isComingSoon: false,
  },
  {
    label: 'Fajr call statistics',
    isPlus: true,
    isPremium: false,
    isComingSoon: false,
  },
  {
    label: 'Custom Adhaans',
    isPlus: true,
    isPremium: false,
    isComingSoon: false,
  },
  {
    label: 'Call time preferences',
    isPlus: true,
    isPremium: false,
    isComingSoon: false,
  },
  // {
  //   label: 'Sadqah Jaariyah',
  //   isPlus: true,
  //   isPremium: false,
  //   isComingSoon: false,
  // },
  {
    label: 'Do not disturb detection',
    isPlus: true,
    isPremium: false,
    isComingSoon: false,
  },
  {
    label: 'Repeat unanswered Fajr calls',
    isPlus: false,
    isPremium: true,
    isComingSoon: false,
  },
  {
    label: 'Additional phone number',
    isPlus: false,
    isPremium: true,
    isComingSoon: true,
  },
  {
    label: 'Personalised Fajr calls for friends',
    isPlus: false,
    isPremium: true,
    isComingSoon: true,
  },
];

export function PremiumFeatures({ showPro = false, showSuper = false }) {
  return (
    <List spacing={3} textAlign="start" fontSize={['sm', 'md']} px={[1, 4]}>

      {features.map((f) => (
        <ListItem key={f.label}>
          <ListIcon
            as={f.isPremium ? showSuper ? FaCheckCircle : FaTimesCircle : f.isPlus ? showPro ? FaCheckCircle : FaTimesCircle : FaCheckCircle}
            color={f.isPremium ? showSuper ? 'green.500' : 'red.500' : f.isPlus ? showPro ? 'green.500' : 'red.500' : 'green.500'}
          />
          {f.label}
          {' '}
          {f.isComingSoon ? <Tag size="sm">Coming Soon</Tag> : null}
        </ListItem>
      ))}
    </List>
  );
}

export default function ThreeTierPricing() {
  return (
    <Container maxW="5xl" py={12} id="pricing">
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" size="2xl">
          Pricing
        </Heading>
      </VStack>
      <Container maxW="3xl">
        <Text fontSize={'xl'}>
          <br />
          We are a non-profit team with an aim to help serve the Muslim community and build a service that benefits you.
          We would love to provide this service free of charge however this would prevent us from scaling our service.
          <br />
          <br />

          We hope you understand our concerns and help us wake more people for Fajr.
          <br />
          <br />
          However if you are unable to afford the plans below please get in touch with us
          at <Link href={`mailto:salaam@fajr.io`}>salaam@fajr.io</Link>
        </Text>
      </Container>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        textAlign="center"
        justify="center"
        align={{ base: 'center', lg: 'stretch' }}
        spacing={{ base: 4, lg: 10 }}
        py={10}
      >
        <BasicPricingCard>
          <TryItButton/>
        </BasicPricingCard>

        <PlusPricingCard>
          <TryItButton/>
        </PlusPricingCard>

        <PremiumPricingCard>
          <TryItButton/>
        </PremiumPricingCard>
      </Stack>
    </Container>
  );
}

export const BasicPricingCard = (props) => {
  const {staticPrices, staticIsStaticLoading} = useContext(AppContext);

  const currency = getCurrency(navigator.language || 'en-US').toLowerCase()
  const curFormatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency', currency: currency || 'usd',
  });

  const price = staticPrices?.basic?.currencies[currency]?.unit_amount || 199

  const formattedPrice = curFormatter.format(price / 100)

  return (
    <PricingCard
      title={<Heading as="h1" size="xl">Basic Plan</Heading>}
      subtitle=""
      price={formattedPrice}
      features={features.filter(f => !f.isPlus && !f.isPremium)}
      isLoading={staticIsStaticLoading}
      {...props}
    />
  )
}

export const PlusPricingCard = (props) => {
  const {staticPrices, staticIsStaticLoading} = useContext(AppContext);

  const currency = getCurrency(navigator.language || 'en-US').toLowerCase()
  const curFormatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency', currency: currency || 'usd',
  });

  const price = staticPrices?.plus?.currencies[currency]?.unit_amount || 399

  const formattedPrice = curFormatter.format(price / 100)
  return (
    <PricingCard
      title={<Heading as="h1" size="xl" bgGradient={premiumPlans.TIER2.bgGradient} bgClip="text">Plus Plan</Heading>}
      subtitle=""
      price={formattedPrice}
      features={features.filter(f => !f.isPremium)}
      isLoading={staticIsStaticLoading}
      {...props}
    />
  )
}

export const PremiumPricingCard = (props) => {
  const {staticPrices, staticIsStaticLoading} = useContext(AppContext);

  const currency = getCurrency(navigator.language || 'en-US').toLowerCase()
  const curFormatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency', currency: currency || 'usd',
  });

  const price = staticPrices?.premium?.currencies[currency]?.unit_amount || 599

  const formattedPrice = curFormatter.format(price / 100)
  return (
    <PricingCard
      title={<Heading as="h1" size="xl" bgGradient={premiumPlans.TIER3.bgGradient} bgClip="text">Premium Plan</Heading>}
      subtitle=""
      price={formattedPrice}
      features={features}
      isLoading={staticIsStaticLoading}
      {...props}
    />
  )
}

function PricingCard({
  title, subtitle, price, features=[], isLoading=false, children=null
}) {
  const navigate = useNavigate();
  return (
    <Card maxWidth="450px">
      <VStack spacing={4} height={'100%'}>
        <Skeleton isLoaded={!isLoading} width={'100%'}>
          <VStack spacing={0}>
            {title}
            <Text fontSize="sm" color="gray.500">
              {subtitle}
            </Text>
          </VStack>
        </Skeleton>
        <Skeleton isLoaded={!isLoading} width={'100%'}>
          <VStack spacing={2} align="center" justify="center">
            <Text fontSize={['3xl', '4xl']} fontWeight="bold">
              {price}
            </Text>
            <Text fontSize="xl" color="gray.500">
              a month
            </Text>
          </VStack>
        </Skeleton>
        <SkeletonText
          isLoaded={!isLoading} width={'100%'}
          noOfLines={7} spacing={4}
        >
          <VStack pt={[2, 2, 8]} pb={6}>
            <List spacing={3} textAlign="start" fontSize={['sm', 'md']} px={[1, 4]}>
              {features.map((f) => (
                <ListItem key={f.label}>
                  <ListIcon
                    as={FaCheckCircle}
                    color={'green.500'}
                  />
                  {f.label}
                  {' '}
                  {f.isComingSoon ? <Tag size="sm">Coming Soon</Tag> : null}
                </ListItem>
              ))}
            </List>
          </VStack>
        </SkeletonText>

        {children}

      </VStack>

    </Card>

  );
}

const TryItButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      colorScheme="teal"
      bg="teal.400"
      rounded="full"
      px={6}
      marginTop={'auto !important'}
      onClick={() => navigate('signup')}
      _hover={{
        bg: 'teal.500',
      }}
    >
      Try it out for free
    </Button>
  )
}
