import {
  VStack, Spinner,
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AppContext } from '../../providers/AppProvider'
import AccountCard from '../AccountCard'

function SubscriptionDefault () {

  let [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [subData, setSubData] = useState(null)

  const {
    showErrorToast, showSuccessToast,
    fetchStripeSession, user, isAuthenticated,
  } = useContext(AppContext)

  useEffect(() => {
    if (isAuthenticated && searchParams) {
      const session_id = searchParams.get('session_id')
      if (session_id) {
        setIsLoading(true)
        fetchStripeSession(session_id).
          then(response => setSubData(response?.data?.result)).
          catch(e => setError(e.message)).finally(_ => setIsLoading(false))
      }
    }
  }, [searchParams, isAuthenticated])

  if (isLoading) {
    return (
      <VStack py={10} px={6}>
        <Spinner/>
      </VStack>
    )
  } else {
    return (
      <VStack py={10} px={6}>
        <AccountCard />
      </VStack>
    )
  }
}

export default SubscriptionDefault
