import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader, DrawerBody,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../providers/AppProvider';
import LoginForm from '../components/LoginForm';
import ProUserCheckout from '../components/ProUserCheckout';

function AuthenticatedAppContainer() {
  const navigate = useNavigate();

  const {
    user, isUserLoading, showGoPremium, setShowGoPremium, isNative,
    nativeSendMessage
  } = useContext(AppContext);

  useEffect(() => {
    if (isNative && user == null && !isUserLoading) {
      nativeSendMessage('login', {})
    }
  }, [isNative, user, isUserLoading])

  return (
    <>
      <Modal isOpen={!isNative && user == null && !isUserLoading} onClose={() => navigate('/')}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Session expired, please sign in</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <LoginForm />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Drawer isOpen={isNative && user == null && !isUserLoading}
              size={'full'}
      >
        <DrawerOverlay />
        <DrawerContent
          maxHeight="100%"
        >
          <DrawerHeader>Sign In</DrawerHeader>
          <ModalCloseButton />
          <DrawerBody>
            <LoginForm />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Outlet />

      <Drawer
        isOpen={showGoPremium}
        placement="bottom"
        onClose={() => setShowGoPremium(false)}
      >
        <DrawerOverlay />
        <DrawerContent maxHeight="100%">
          <DrawerCloseButton />
          <DrawerHeader>Upgrade Plan</DrawerHeader>

          <DrawerBody pb="5vh">
            <ProUserCheckout />
          </DrawerBody>

        </DrawerContent>
      </Drawer>

    </>
  );
}

export default AuthenticatedAppContainer;
