import {
  Box,
  Button, IconButton,
  Tag,
  Heading,
  Text,
  Image,
  VStack,
  HStack,
  useColorModeValue,
  Flex,
  Collapse, useDisclosure,
  Link,
} from '@chakra-ui/react'
import {
  HamburgerIcon,
  CloseIcon,
} from '@chakra-ui/icons';
import { AiOutlineSetting, AiOutlineCalendar, AiOutlineUser, AiOutlineQuestion } from 'react-icons/ai';
import { ColorModeSwitcher } from '../ColorModeSwitcher'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { AppContext } from '../providers/AppProvider'
import logoColor from '../assets/logo/logo_color.png'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import ReactGA from 'react-ga4'

function AppContainer () {
  const navigate = useNavigate()
  const location = useLocation()

  const { isOpen, onToggle } = useDisclosure();

  const { fetchCurrentUser, token, user, isNative, doSignOut, tawkMessengerRef} = useContext(AppContext)

  const navItems = [
    {
      label: 'My Account',
      onClick: () => {
        navigate('/app')
        ReactGA.event({
          action: 'Clicked Account Page'
        })
      },
      display: user == null ? 'none' : 'block'
    },
    {
      label: 'Fajr Schedule',
      onClick: () => {
        navigate("/app/schedule")
        ReactGA.event({
          action: 'Clicked Schedule Page'
        })
      },
      display: user == null ? 'none' : 'block'
    },
    {
      label: 'Fajr Settings',
      onClick: () => {
        navigate("/app/settings")
        ReactGA.event({
          action: 'Clicked Settings Page'
        })
      },
      display: user == null ? 'none' : 'block'
    },
    {
      label: user == null ? 'Sign In' : 'Sign Out',
      onClick: () => user == null ? navigate("/signin") : doSignOut(),
      variant: 'solid'
    },
    {
      label: 'Help',
      onClick: () => navigate("/help"),
    },
  ];

  // useEffect(() => {
  //   if (tawkMessengerRef?.current) {
  //     console.log('tawkMessengerRef?.current', tawkMessengerRef?.current)
  //     tawkMessengerRef.current.hideWidget();
  //   }
  // }, [isNative, tawkMessengerRef])

  useEffect(() => {
    fetchCurrentUser();
    if (isOpen) {
      onToggle()
    }
  }, [location?.pathname, token])

  function onTawkLoad() {
    if (isNative && tawkMessengerRef?.current) {
      tawkMessengerRef.current.hideWidget();
    }
  }

  function onTawkChatMinimized() {
    if (isNative && tawkMessengerRef?.current) {
      tawkMessengerRef.current.hideWidget();
    }
  }

  function showTawkHelp() {
    if (isNative && tawkMessengerRef?.current) {
      tawkMessengerRef.current.showWidget();
      tawkMessengerRef.current.maximize();
    }
  }

  function getNavItems() {
    return navItems
  }

  return (
    <Box
      textAlign="center"  minH="100vh"
      flexDir={"column"} overflowX={'hidden'} minWidth={'320px'}
      display={isNative ? 'none' : 'flex'}
    >
      <Flex
        display={isNative ? 'none' : 'flex'}
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
        justify={'space-between'}
        zIndex={10}
      >
        <FajrioLogo/>


        {/* Desktop */}
        <HStack spacing={6} display={{base: 'none', lg: 'flex'}}>
          {getNavItems().map((navItem) => (
            <Button key={navItem.label} variant={navItem?.variant || 'ghost'} colorScheme={'teal'} size={'sm'}
                    {...navItem}
            >
              {navItem.label}
            </Button>
          ))}
          <ColorModeSwitcher justifySelf="flex-end"/>
        </HStack>


        <Flex
          ml={{ base: -2 }}
          display={{ base: 'flex', lg: 'none' }}>
          <ColorModeSwitcher justifySelf="flex-end"/>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
      </Flex>

      <Collapse
        in={isOpen} animateOpacity
        display={isNative ? 'none' : 'block'}
      >
        <VStack
          pt={6}
          px={5}
          py={5}
          spacing={6}
          borderBottom={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.900')}
          align={'start'}
          display={{base: 'flex', lg: 'none' }}
        >
          {getNavItems().map((navItem) => (
            <Button
              key={navItem.label} variant={navItem?.variant || 'link'} colorScheme={'teal'} size={'md'} onClick={navItem.onClick}
              {...navItem}
            >
              {navItem.label}
            </Button>
          ))}
        </VStack>
      </Collapse>

      <Outlet/>


      <TawkMessengerReact
        propertyId="6319c0b937898912e967f2c7"
        widgetId="1gce9hlq0"
        ref={tawkMessengerRef}
        onLoad={onTawkLoad}
        onChatMinimized={onTawkChatMinimized}
      />

      <Flex
        display={isNative ? 'flex' : 'none'}
        position={'fixed'}
        bottom={0}
        width={'100%'}
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        px={{ base: 4 }}
        // pb={5}
        borderTop={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
        justify={'space-around'}
        zIndex={20}>

        <IconButton
          width={'100%'} icon={<AiOutlineUser size={'20'}/>}
          variant={'ghost'}
          onClick={() => navigate('/app')}
          color={location?.pathname?.endsWith("/app") ? 'teal.500' : ''}
        />
        <IconButton
          width={'100%'} icon={<AiOutlineCalendar size={'20'}/>}
          variant={'ghost'}
          onClick={() => navigate('/app/schedule')}
          color={location?.pathname?.endsWith("/schedule") ? 'teal.500' : ''}
        />
        <IconButton
          width={'100%'} icon={<AiOutlineSetting size={'20'}/>}
          variant={'ghost'}
          onClick={() => navigate('/app/settings')}
          color={location?.pathname?.endsWith("/settings") ? 'teal.500' : ''}
        />
        <IconButton
          width={'100%'} icon={<AiOutlineQuestion size={'20'}/>}
          variant={'ghost'}
          onClick={showTawkHelp}
        />
      </Flex>

      {/*<FeedbackModal/>*/}

    </Box>
  )
}

export function FajrioLogo() {
  const navigate = useNavigate()
  return (
    <HStack spacing={2} align={"center"} onClick={() => navigate('/')} cursor={'pointer'}>
      <FajrioIcon />
      <VStack spacing={0} align={"start"} onClick={() => navigate('/')} cursor={'pointer'}>
        <HStack>
          <Heading size={'lg'}>Fajr.io</Heading>
          {/*<Tag size={'md'} bg={'gray.500'} color={'white'} variant='solid'>*/}
          {/*  Beta*/}
          {/*</Tag>*/}
        </HStack>

        <Text fontSize={'xs'} textAlign={"left"} color={useColorModeValue("gray.600", "gray.500")}>
          Never miss Fajr again.
        </Text>
      </VStack>
    </HStack>

  )
}

export function FajrioIcon(props) {
  return (
    <Image src={logoColor} height={'40px'} width={'auto'} borderRadius={'8px'} {...props}/>
  )
}

export default AppContainer
