import {
  Flex, Heading,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Text, useColorModeValue,
  VStack, Container,
  Box,
} from '@chakra-ui/react';
import React from 'react';
import Card from '../../components/Card'
import timeCalculation from '../../assets/timeCalculation.svg'

function LandingHowItWorks() {
  return (
    <Container maxW="5xl" py={12} id="pricing">
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" size="2xl">
          How It Works
        </Heading>
      </VStack>
      <Container maxW="3xl" position="relative">

        <VStack spacing={0} pt={6}>

          <Card padding={0} height={'250px'} pr={4}>
            <Flex justify={'space-between'} align={'center'} height={'100%'}>
              <Box flex={1} height={'100%'} position={'relative'}>
                <VStack
                  justify={'center'}
                  height={'100%'} width={'100%'}
                  position={'absolute'}
                  left={-10}
                  top={1}
                  spacing={4}
                >
                  <Flex
                    height={'50px'} width={'100%'}
                    justify={'start'} align={'center'}
                    borderRadius={'8px'}
                    bg={useColorModeValue('gray.100', 'gray.800')}
                    color={useColorModeValue('gray.400', 'gray.700')}
                  >
                    <Text fontSize={'xl'}>+44786786786</Text>
                  </Flex>
                  <Flex
                    height={'50px'} width={'100%'}
                    justify={'start'} align={'center'}
                    borderRadius={'8px'}
                    bg={useColorModeValue('gray.100', 'gray.800')}
                    color={useColorModeValue('gray.400', 'gray.700')}
                  >
                    <Text fontSize={'2xl'}>*****************</Text>
                  </Flex>
                </VStack>
              </Box>
              <Text flex={1} fontSize={'3xl'} fontWeight={'bold'}>
                Sign Up With <br/>Your Phone Number
              </Text>
            </Flex>
          </Card>

          <Card padding={3} height={'250px'}>
            <Flex justify={'space-between'} align={'center'} height={'100%'}>
              <Text flex={1} fontSize={'3xl'} fontWeight={'bold'}>
                Enter Timezone<br/> and City
              </Text>
              <Flex flex={1} height={'100%'} align={'center'} justify={'end'}>
                <Image src={timeCalculation} height={'200%'}/>
              </Flex>
            </Flex>
          </Card>

          <Card padding={0} height={'250px'}>
            <Flex justify={'space-between'} align={'center'} height={'100%'}>
              <Box bg={'red'} flex={1} height={'100%'}/>
              <Text flex={1} fontSize={'4xl'} fontWeight={'bold'}>Gain reward</Text>
            </Flex>
          </Card>

        </VStack>

      </Container>
    </Container>
  );
}

export default LandingHowItWorks;
