import {
  Button,
  Heading,
  VStack, Spinner, Text, Table, Tr, Tbody, Td, TableContainer,
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { CheckCircleIcon } from '@chakra-ui/icons'
import Card from '../Card'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AppContext } from '../../providers/AppProvider'

function SubscriptionSuccess () {

  let [searchParams,] = useSearchParams()
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [subData, setSubData] = useState(null)

  const {
    showErrorToast, showSuccessToast,
    fetchStripeSession, user, isAuthenticated,
  } = useContext(AppContext)

  useEffect(() => {
    if (isAuthenticated && searchParams) {
      const session_id = searchParams.get('session_id')
      if (session_id) {
        setIsLoading(true)
        fetchStripeSession(session_id).
          then(response => setSubData(response?.data?.result))
          .catch(e => showErrorToast({message: e.message }))
          .finally(_ => setIsLoading(false))
      }
    }
  }, [searchParams, isAuthenticated])

  if (isLoading) {
    return (
      <VStack py={10} px={6}>
        <Spinner/>
      </VStack>
    )
  } else {
    return (
      <VStack py={10} px={6}>
        <Card
          display={subData == null ? 'none' : 'flex'}
          textAlign="center" fontSize={'md'} size={'md'}
        >
          <VStack spacing={6}>
            <VStack spacing={3}>
              <CheckCircleIcon boxSize={'50px'} color={'green.500'}/>
              <Heading size="md" mt={6} mb={2}>
                Payment Successful
              </Heading>
            </VStack>
            <Text >
              Thanks for supporting us {subData?.customer_details?.name}! <br/>
              We hope
              Allah accepts our efforts and yours, and that you never miss Fajr
              again insha'Allah!
            </Text>
            <VStack spacing={1} align={'start'} px={'10%'}>
              <Text fontWeight={'bold'} fontSize={'lg'}
                    textAlign="center" width={"100%"}>
                Payment Details
              </Text>
              <TableContainer width={'100%'}>
                <Table variant="unstyled" size={'sm'}>
                  <Tbody display={isLoading ? 'none' : 'table-row-group'}>
                    <Tr >
                      <Td>Name</Td>
                      <Td>{subData?.customer_details?.name}</Td>
                    </Tr>
                    <Tr >
                      <Td>Email</Td>
                      <Td>{subData?.customer_details?.email}</Td>
                    </Tr>
                    <Tr >
                      <Td>Postcode</Td>
                      <Td>{subData?.customer_details?.address?.postal_code}</Td>
                    </Tr>
                    <Tr >
                      <Td>Total</Td>
                      <Td>{subData?.amount_total / 100} {subData?.currency?.toUpperCase()}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </VStack>

            <Button onClick={() => navigate("/app")}>Back to my account</Button>

          </VStack>
        </Card>

      </VStack>
    )
  }
}

export default SubscriptionSuccess
