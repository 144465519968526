import {
  Container, Divider,
  Heading,
  Stack,
  VStack, HStack,
} from '@chakra-ui/react'
import React from 'react';
import CallsStatsCard from '../components/CallsStatsCard';
import DeleteAccountCard from '../components/DeleteAccountCard';
import CurrentFajrSettingsCard
  from '../components/CurrentFajrSettingsCard';
import AccountCard, {
} from '../components/AccountCard';
import TestCallCard from '../components/TestCallCard';
import FreeTrialEndAlert from '../components/FreeTrialEndAlert';
import VerifyPhoneAlert from '../components/VerifyPhoneAlert';
import SignOutCard from '../components/SignOutCard'
import { FajrioIcon } from './AppContainer'
import { Helmet } from 'react-helmet'

function AccountPage() {

  return (
    <Container py={5} maxW="container.lg" paddingTop="5vh">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fajr.io | Account</title>
      </Helmet>

      <Stack spacing={8} align={'center'}>

        <HStack spacing={4} px={4} align={'start'} justify={'start'} width={'100%'}>
          <FajrioIcon/>
          <Heading textAlign={'left'} as="h2">Your Account</Heading>
        </HStack>
        <Container paddingTop={'0 !important'} >
          <VStack spacing={8}>

            <FreeTrialEndAlert />

            <VerifyPhoneAlert />

            <CallsStatsCard />

            <CurrentFajrSettingsCard />

            <TestCallCard />

            <AccountCard />

            <DeleteAccountCard />

            <Divider />

            <SignOutCard />

            {/* {renderPreferenceOptions()} */}
          </VStack>
        </Container>
      </Stack>

    </Container>
  );
}

export default AccountPage;
