import { Box, useColorModeValue, Spinner } from '@chakra-ui/react';
import React from 'react';

function LoadingPanel({ isLoading, children }) {
  return (
    <Box
      w="full"
      position="relative"
      overflowX="hidden"
      onClick={(e) => e.stopPropagation()}
    >
      <Spinner
        size="lg"
        position="fixed"
        color="teal.500"
        zIndex="12"
        mt={10}
        display={isLoading ? 'inline-block' : 'none'}
      />
      <Box
        display={isLoading ? 'block' : 'none'}
        position="absolute"
        width="100%"
        height="100%"
        bg={useColorModeValue('white', 'gray.800')}
        opacity={0.8}
        onClick={(e) => e.stopPropagation()}
        zIndex="1"
        px={10}
        py={10}
      />
      {children}
    </Box>
  );
}
export default LoadingPanel;
