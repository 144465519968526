import {
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
} from '@chakra-ui/react'
import codes from 'country-calling-code'
import "/node_modules/flag-icons/css/flag-icons.min.css";

import React, { useEffect, useState } from 'react'

const supportedCodes = codes.sort((a, b) => a.country.localeCompare(b));

const PhoneNumberInput = ({ onChange, isLoading = false }) => {
  const [countryPhoneCode, setCountryPhoneCode] = useState('44')
  const [countryCode, setCountryCode] = useState('GB')
  const [phoneNumber, setPhoneNumber] = useState('')

  useEffect(() => {
    onChange(`+${countryPhoneCode}${phoneNumber}`)
  }, [countryPhoneCode, phoneNumber])

  useEffect(() => {
    if (countryCode) {
      const phoneCode = codes.find(c => c.isoCode2 == countryCode)
      setCountryPhoneCode(phoneCode?.countryCodes[0])
    }
  }, [countryCode])

  function onCountryChange (event) {
    const isoCode = event.target.value
    setCountryCode(isoCode)
  }

  function onPhoneNumberChange (event) {
    setPhoneNumber(`${event.target.value}`)
  }

  return (
    <InputGroup>
      <InputLeftAddon>
        <HStack spacing={0}>

          <HStack spacing={2} width={'80px'} align={'center'}>
            <div className={`fi fi-${countryCode?.toLowerCase()}`}></div>
            <Text >+{countryPhoneCode}</Text>
          </HStack>
          <Select width={'30px'} variant={'unstyled'}
                  onChange={onCountryChange}
                  defaultValue={'GB'}
          >
            {supportedCodes.map(c => {
                return (
                  <option value={c.isoCode2} key={c.isoCode2}>
                    {`${c.country}`}
                  </option>
                )
              })
            }
          </Select>
        </HStack>
      </InputLeftAddon>
      <Input
        id="tel"
        type="tel"
        placeholder="7098765432"
        disabled={isLoading}
        onChange={onPhoneNumberChange}
      />
    </InputGroup>
  )
}

export default PhoneNumberInput
