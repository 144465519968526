import {
  Container,
  Heading, HStack,
  Stack,
} from '@chakra-ui/react'
import React from 'react';
import ErrorBoundary from '../components/ErrorBoundary';
import FajrSchedule from '../components/FajrSchedule';
import FreeTrialEndAlert from '../components/FreeTrialEndAlert'
import { FajrioIcon } from './AppContainer'
import { Helmet } from 'react-helmet'

function FajrSchedulePage() {
  return (
    <Container py={5} px={0} maxW="container.lg" paddingTop="5vh">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fajr.io | Schedule</title>
      </Helmet>

      <Stack spacing={8} align={'center'}>

        <HStack spacing={4} px={4} align={'start'}>
          <FajrioIcon/>
          <Heading textAlign={'left'} as="h2">Your Fajr Call Schedule</Heading>
        </HStack>

        <FreeTrialEndAlert />

        <ErrorBoundary>
          <FajrSchedule />
        </ErrorBoundary>

      </Stack>
    </Container>
  );
}

export default FajrSchedulePage;
