import {
  Button,
  IconButton,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Select,
  Divider,
  Text,
  VStack,
  Link,
  Switch,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Accordion, useColorModeValue,
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../providers/AppProvider'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'react-use'
import LoadingPanel from './LoadingPanel'
import { FaPause, FaPlay } from 'react-icons/fa'
import { AiOutlineCalendar } from 'react-icons/ai'
import { premiumPlans } from './PricingView'
import LocationInput from './LocationInput'
import ReactGA from 'react-ga4'

function EditPrayerCalculationView (props) {

  const [selectedCountryName, setSelectedCountryName] = useState(null)
  const [selectedCountryCode, setSelectedCountryCode] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [timezones, setTimezones] = useState([])
  const [selectedTimezone, setSelectedTimezone] = useState('')
  const [selectedCityName, setSelectedCityName] = useState('')
  const [prayerCalcMethods, setPrayerCalcMethods] = useState([])
  const [selectedPrayerCalcMethod, setSelectedPrayerCalcMethod] = useState(null)
  const [prayerHighLats, setPrayerHighLats] = useState([])
  const [selectedPrayerHighLat, setSelectedPrayerHighLat] = useState(null)
  const [lat, setLat] = useState(null)
  const [lng, setLng] = useState(null)

  const [calculationTabIndex, setCalculationTabIndex] = useState(0)
  const [mosques, setMosques] = useState([])
  const [selectedMosqueId, setSelectedMosqueId] = useState(null)

  const [allowCalls, setAllowCalls] = useState(false)

  const [proFajrAdhaans, setProFajrAdhaans] = useState([])
  const [proFajrAdhaanTitle, setProFajrAdhaanTitle] = useState('Default')
  const [adhaanAudio, setAdhaanAudio] = useState(null)
  const [isAdhaanPlaying, setIsAdhaanPlaying] = useState(false)

  const [proFajrCallPrefs, setProFajrCallPrefs] = useState([])
  const [proFajrCallPrefTitle, setProFajrCallPrefTitle] = useState(null)

  const [allowRepeatCalls, setAllowRepeatCalls] = useState(false)

  const [showRequestMosqueModal, setShowRequestMosqueModal] = useState(false)
  const [requestMosqueLoading, setRequestMosqueLoading] = useState(false)
  const [requestMosqueName, setRequestMosqueName] = useState('')
  const [requestMosqueAddress, setRequestMosqueAddress] = useState('')

  const {
    showErrorToast, showSuccessToast,
    fetchPrayerCalcData, fetchCurrentUser, postMakeMosqueRequest,
    fetchDataCities, updatePrefs, updatePrefsToggles,
    user, staticFajrioPhoneNumber,
  } = useContext(
    AppContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (user != null) {
      setCalculationTabIndex(user?.mosqueId == null ? 0 : 1)
      setSelectedTimezone(user?.tzName)
      setSelectedPrayerCalcMethod(user?.prayerCalcMethod)
      setSelectedPrayerHighLat(user?.prayerCalcHlat)
      setSelectedCityName(user?.cityName)
      setSelectedCountryName(user?.countryName)
      setSelectedCountryCode(user?.countryCode)
      setLat(user?.lat)
      setLng(user?.lng)
      setSelectedMosqueId(user?.mosqueId)
      setAllowCalls(user?.allowCalls)
      setProFajrAdhaanTitle(user?.fajrAdhaanId || 'Default')
      setProFajrCallPrefTitle(user?.fajrCallTimePrefTitle || 'As soon as Fajr starts')
      setAllowRepeatCalls(user?.allowRepeatCalls)
      setIsLoading(true)
      fetchPrayerCalcData().then((response) => {
        const data = response?.data?.result
        setTimezones(data?.timezones || [])
        setPrayerCalcMethods(data?.prayerCalcMethods || [])
        setPrayerHighLats(data?.prayerCalcHighLats || [])
        setMosques(data?.mosques)
        setProFajrAdhaans(data?.fajrAdhaans)
        setProFajrCallPrefs(data?.fajrCallTimePrefs)
      }).catch(e => {
        showErrorToast({ message: e.message })
        setTimezones([])
        setPrayerCalcMethods([])
        setPrayerHighLats([])
      }).finally(() => setIsLoading(false))
    }
  }, [user])

  /**
   * We want to leave timezone null if the user changes their location,
   * this is so we can calcualte their timezone based off location.
   * If the user however sets their timezone manually after, we will override.
   */
  useEffect(() => {
    if (selectedCityName && selectedCityName !== user?.cityName) {
      setSelectedTimezone('')
    }
  }, [selectedCityName])

  /**
   * When user has not selected a Fajr Adhaan and we have loaded all Fajr adhaans,
   * we want to set the default select adhaan
   */
  useEffect(() => {
    if (proFajrAdhaanTitle == null && proFajrAdhaans != null && proFajrAdhaans.length > 0) {
      setProFajrAdhaanTitle(proFajrAdhaans?.find(a => a.title === 'Default')?.title)
    }
  }, [proFajrAdhaanTitle, proFajrAdhaans])

  /**
   * When a user selects a Fajr adhaan, we create an Audio object,
   * so that when user clicks play button we can hear the sound
   */
  useEffect(() => {
    if (proFajrAdhaanTitle) {
      const adhaan = proFajrAdhaans?.find(a => a.title === proFajrAdhaanTitle)
      if (adhaan) {
        pauseSelectedAdhaan()
        const audio = new Audio(adhaan.link)
        setAdhaanAudio(audio)
      }
    }
  }, [proFajrAdhaanTitle, proFajrAdhaans])

  useEffect(() => {
    if (calculationTabIndex) {
      ReactGA.event({
        action: 'Fajr Settings: Clicked Pray At Mosque'
      })
    } else {
      ReactGA.event({
        action: 'Fajr Settings: Clicked Pray At Home'
      })
    }
  }, [calculationTabIndex])

  useEffect(() => {
    if (showRequestMosqueModal) {
      ReactGA.event({
        action: `New Mosque Request: Opened Modal`
      })
    }
  }, [showRequestMosqueModal])

  useEffect(() => {
    if (selectedTimezone) {
      ReactGA.event({
        action: `Fajr Settings: Selected Timezone`
      })
    }
  }, [selectedTimezone])

  useEffect(() => {
    if (selectedPrayerCalcMethod) {
      ReactGA.event({
        action: `Fajr Settings: Selected Prayer Calc Method`
      })
    }
  }, [selectedPrayerCalcMethod])

  useEffect(() => {
    if (selectedPrayerHighLat) {
      ReactGA.event({
        action: `Fajr Settings: Selected Prayer High Lat`
      })
    }
  }, [selectedPrayerHighLat])

  useEffect(() => {
    if (selectedMosqueId) {
      ReactGA.event({
        action: `Fajr Settings: Selected Mosque`
      })
    }
  }, [selectedMosqueId])

  function onTabsChange(index) {
    setCalculationTabIndex(index)
  }

  function onLocationChange(data) {
    setLat(data.lat)
    setLng(data.lng)
    setSelectedCountryName(data.countryName)
    setSelectedCountryCode(data.countryCode)
    setSelectedCityName(data.location)
  }

  function update () {
    setIsLoading(true)
    updatePrefs({
      allowCalls: allowCalls,
      countryName: selectedCountryName,
      countryCode: selectedCountryCode,
      cityName: selectedCityName,
      lat: lat,
      lng: lng,
      // tzName: selectedTimezone,
      prayerCalcMethod: selectedPrayerCalcMethod,
      prayerCalcHlat: selectedPrayerHighLat,
      mosqueId: calculationTabIndex === 1 ? selectedMosqueId : null,
      fajrAdhaanId: proFajrAdhaanTitle,
      fajrCallDelayPref: proFajrCallPrefTitle,
      allowRepeatCalls: allowRepeatCalls,
    }).
      then(response => {
        showSuccessToast({ message: 'Updated preferences' })
        fetchCurrentUser()
      }).
      catch(e => showErrorToast({ message: e.message })).
      finally(_ => setIsLoading(false))

    ReactGA.event({
      action: 'Fajr Settings: Clicked Update'
    })
  }

  function updateAllowRepeatCalls(value) {
    const currentVal = allowRepeatCalls;
    setAllowRepeatCalls(prev => !prev)
    updatePrefsToggles({
      allowRepeatCalls: value,
    }).
      then(response => {
        showSuccessToast({ message: `Fajr repeated calls ${value ? 'enabled' : 'disabled'}` })
      }).
      catch(e => {
        showErrorToast({ message: e.message })
        setAllowRepeatCalls(currentVal)
      })
  }

  function updateAllowCalls(value) {
    const currentVal = allowCalls;
    setAllowCalls(prev => !prev)
    updatePrefsToggles({
      allowCalls: value,
    }).
      then(response => {
        showSuccessToast({ message: `Fajr calls ${value ? 'enabled' : 'disabled'}` })
      }).
      catch(e => {
        showErrorToast({ message: e.message })
        setAllowCalls(currentVal)
      })
    ReactGA.event({
      action: `Fajr Settings: ${value ? 'Enabled' : 'Disabled'} Allow Calls Toggle`
    })
  }


  function sendNewMosqueRequest () {
    setRequestMosqueLoading(true)
    postMakeMosqueRequest(requestMosqueName, requestMosqueAddress).
      then(response => {
        showSuccessToast({ message: 'Request Sent' })
      }).
      catch(e => showErrorToast({ message: e.message })).
      finally(_ => setRequestMosqueLoading(false))
    ReactGA.event({
      action: `New Mosque Request: Submitted`
    })
  }

  function playSelectedAdhaan () {
    if (adhaanAudio) {
      adhaanAudio.play()
      setIsAdhaanPlaying(true)
    }
  }

  function pauseSelectedAdhaan () {
    if (adhaanAudio) {
      adhaanAudio.pause()
      setIsAdhaanPlaying(false)
    }
  }

  function goToSchedule() {
    navigate('/app/schedule')
  }

  return (
    <LoadingPanel isLoading={isLoading}>
      <VStack spacing={6} align={'start'} px={3} py={3} paddingBottom={'100px'}>

        <HStack width={'100%'} align={'start'}>
          <Button leftIcon={<AiOutlineCalendar />} onClick={goToSchedule} mt={'10px'}>
            View Schedule
          </Button>
        </HStack>

        <Tabs width={'100%'} isLazy isFitted variant='soft-rounded' px={'0 !important'}
              onChange={onTabsChange} index={calculationTabIndex}
        >
          <TabList bg={useColorModeValue('gray.200', 'gray.900')} borderRadius={'25px'} padding={'5px'}>
            <Tab
              _selected={{ color: useColorModeValue('gray.900', 'gray.200'), bg: useColorModeValue('white', 'gray.800') }}
            >
              Pray at Home
            </Tab>
            <Tab
              _selected={{ color: useColorModeValue('gray.900', 'gray.200'), bg: useColorModeValue('white', 'gray.800') }}
            >
              Pray at Mosque
            </Tab>
          </TabList>
          <TabPanels px={'0 !important'}>
            <TabPanel>
              <VStack width={'100%'}>

                {/* City */}
                <VStack width={'100%'} align={'start'}>
                  <FormControl align={'start'}>
                    <FormLabel htmlFor={'city'}>
                      Location
                    </FormLabel>
                    <LocationInput
                      defaultValue={selectedCityName}
                      onComplete={onLocationChange}
                    />
                    <FormHelperText textAlign={'left'}>
                      We'll calculate our Fajr time based on your city,
                      if your town isn't here please try the closest city to
                      you.
                    </FormHelperText>
                  </FormControl>
                </VStack>

                <Accordion allowToggle w={'full'} pt={'20px'}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Text flex='1' textAlign='left' fontWeight={'bold'}>
                          Advanced Settings
                        </Text>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} px={1}>

                      {/* Timezone */}
                      <VStack width={'100%'} align={'start'} display={'none'}>
                        <FormControl>
                          <FormLabel htmlFor={'timezones'}>
                            Timezone
                          </FormLabel>
                          <Select id={'timezones'}
                                  value={selectedTimezone}
                                  onChange={(event) =>
                                    setSelectedTimezone(event.target.value)}
                          >
                            <option value={''}>Please select a timezone</option>
                            {timezones?.map(c => <option key={c.tzName}
                                                         value={c.tzName}>{c.tzName}</option>)}
                          </Select>
                          <FormHelperText textAlign={'left'}>
                            We automatically calculate the timezone from your location,
                            however if you want to customise your timezone you can change it here.
                          </FormHelperText>
                        </FormControl>
                      </VStack>

                      <VStack width={'100%'} spacing={6} align={'start'} pt={3}>
                        {/* Prayer Calculation Method */}
                        <VStack width={'100%'} align={'start'}>
                          <FormControl align={'start'}>
                            <FormLabel htmlFor={'method'}>
                              Prayer Calculation Method
                            </FormLabel>
                            <Select id={'method'} value={selectedPrayerCalcMethod}
                                    onChange={(event) => {
                                      setSelectedPrayerCalcMethod(event.target.value)
                                    }}>
                              {prayerCalcMethods?.map(method =>
                                <option key={method?.name} value={method?.name}>
                                  {method?.name} {method?.isDefault ? ' (Default)' : ''}
                                </option>
                              )}
                            </Select>
                            <FormHelperText textAlign={'left'}>
                              We use these methods to calculate Fajr and Isha times,
                              the main difference is the angles used for calculations.
                              You can learn more here: <Link
                              isExternal>http://praytimes.org/calculation#Calculating_Prayer_Times</Link>
                            </FormHelperText>
                          </FormControl>
                        </VStack>

                        {/* Prayer Calculation High Alt */}
                        <VStack width={'100%'} align={'start'}>
                          <FormControl align={'start'}>
                            <FormLabel htmlFor={'method'}>
                              High Latitude Rule
                            </FormLabel>
                            <Select id={'method'} value={selectedPrayerHighLat}
                                    onChange={(event) => {
                                      setSelectedPrayerHighLat(event.target.value)
                                    }}>
                              {prayerHighLats?.map(method =>
                                <option key={method?.name} value={method?.name}>
                                  {method?.name}  {method?.isDefault ? ' (Default)' : ''}
                                </option>)}
                            </Select>
                            <FormHelperText textAlign={'left'}>
                              In locations at higher latitude, twilight may persist
                              throughout the night during some months of the year.
                              In these abnormal periods, the determination of Fajr and
                              Isha is not possible using just the methods above.
                              To overcome this problem, several solutions have been
                              proposed.
                              You can learn more here: <Link
                              isExternal>http://praytimes.org/calculation#Higher_Latitudes</Link>
                            </FormHelperText>
                          </FormControl>
                        </VStack>
                      </VStack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </VStack>
            </TabPanel>
            <TabPanel px={'0 !important'}>
              <VStack spacing={6} width={'100%'}
                      align={'start'}>
                {/* Mosque */}
                <VStack width={'100%'} align={'start'}>
                  <FormControl align={'start'}>
                    <FormLabel htmlFor={'Mosque'}>
                      Mosque
                    </FormLabel>
                    <Select id={'Mosque'}
                            value={selectedMosqueId}
                            onChange={(event) => setSelectedMosqueId(
                              event.target.value)}
                    >
                      <option>Please select a mosque</option>
                      {mosques?.map(mosque => <option key={mosque?.id}
                                                      value={mosque?.id}>{mosque?.mosqueName}</option>)}
                    </Select>
                    <FormHelperText textAlign={'left'}>
                      {mosques?.find(m => m.id === selectedMosqueId)?.mosqueAddr}
                    </FormHelperText>
                  </FormControl>

                  <Button colorScheme={'gray'} variant={'solid'} align={'start'}
                          onClick={() => setShowRequestMosqueModal(true)}
                  >
                    Can't find your Mosque?
                  </Button>
                </VStack>


                <Modal isOpen={showRequestMosqueModal}
                       onClose={() => setShowRequestMosqueModal(false)}>
                  <ModalOverlay/>
                  <ModalContent>
                    <ModalHeader>
                      <Text>Request your Mosque!</Text>
                      <Text fontSize={'sm'} color={'gray.400'}>We'll try our best to
                        get it added!</Text>
                    </ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                      <VStack spacing={4} align={'start'}>
                        <VStack spacing={0} align={'start'}>

                        </VStack>
                        <FormControl>
                          <FormLabel>Your Mosque Name</FormLabel>
                          <Input type={'text'}
                                 onChange={(event) => setRequestMosqueName(
                                   event.target.value)}/>
                        </FormControl>
                        <FormControl>
                          <FormLabel>Your Mosque Address</FormLabel>
                          <Input type={'text'}
                                 onChange={(event) => setRequestMosqueAddress(
                                   event.target.value)}/>
                        </FormControl>
                      </VStack>
                    </ModalBody>
                    <ModalFooter>
                      <Button isLoading={requestMosqueLoading}
                              onClick={sendNewMosqueRequest}>Submit Request</Button>

                    </ModalFooter>
                  </ModalContent>
                </Modal>

              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <VStack width={['100%']} spacing={6} align={'center'} justify={'center'}
                margin={'auto'}>



          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="allowCalls" mb="0">
              Enable Fajr Calls?
            </FormLabel>
            <Switch id="allowCalls"
                    isChecked={allowCalls}
                    onChange={(event) => updateAllowCalls(event.target.checked)}
            />

          </FormControl>

            <VStack spacing={4} align={'start'} width={'100%'}>
              <VStack width={'100%'}>
                <FormControl align={'start'}>
                  <FormLabel htmlFor={'fajrAdhaan'}>
                    Fajr Adhaan {premiumPlans.TIER2.tag}
                  </FormLabel>
                  <HStack>
                    <Select id={'fajrAdhaan'} value={proFajrAdhaanTitle}
                            onChange={(event) => user?.isPlus
                              ? setProFajrAdhaanTitle(event.target.value)
                              : null}
                            isDisabled={!user?.isPlus}
                    >
                      {proFajrAdhaans?.map(adhaan => <option key={adhaan?.title}
                        value={adhaan?.title}>{adhaan?.title}</option>)}
                    </Select>
                    <IconButton
                      display={isAdhaanPlaying ? 'none' : 'inline-flex'}
                      icon={<FaPlay/>} onClick={playSelectedAdhaan}/>
                    <IconButton
                      display={isAdhaanPlaying ? 'inline-flex' : 'none'}
                      icon={<FaPause/>} onClick={pauseSelectedAdhaan}/>
                  </HStack>
                  <FormHelperText>
                    Customise the adhaan that calls you for Fajr.<br/>
                    {!user?.isPlus && premiumPlans.TIER2.upgradeMessage}
                  </FormHelperText>
                </FormControl>
              </VStack>

              <VStack width={'100%'}>
                <FormControl align={'start'}>
                  <FormLabel htmlFor={'callDelay'}>
                    Fajr Call Time Preference {premiumPlans.TIER2.tag}
                  </FormLabel>
                  <Select id={'callDelay'} value={proFajrCallPrefTitle}
                          onChange={(event) => user?.isPlus
                            ? setProFajrCallPrefTitle(event.target.value)
                            : null}
                          isDisabled={!user?.isPlus}
                  >
                    {proFajrCallPrefs?.map(
                      pref => <option key={pref?.title} value={pref?.title}>{pref?.title}</option>)}
                  </Select>
                  <FormHelperText>
                    Please note this does not affect Local Mosque Jama'ah Times, they are fixed at 30 mins before fajr jama'ah time.<br/>
                    {!user?.isPlus && premiumPlans.TIER2.upgradeMessage}
                  </FormHelperText>
                </FormControl>
              </VStack>
            </VStack>



            <VStack width={'100%'} align={'start'}>
              <FormControl align={'start'}>
                <HStack>
                <FormLabel htmlFor="allowRepeatCalls" mb="0">
                  Repeat unanswered Fajr Calls? {premiumPlans.TIER3.tag}
                </FormLabel>
                <Switch id="allowRepeatCalls"
                        isChecked={allowRepeatCalls}
                        onChange={(event) => updateAllowRepeatCalls(event.target.checked)}
                        isDisabled={!user?.isPremium}
                />
                </HStack>

                <FormHelperText>
                  If you miss one of our calls, we'll reschedule a call 10 minutes later.<br/>
                  {!user?.isPremium && premiumPlans.TIER3.upgradeMessage}
                </FormHelperText>

              </FormControl>
            </VStack>


        </VStack>

        <Flex justify={'space-between'} width={'100%'}>
          <Button onClick={update} colorScheme={'teal'} mt={'10px'}>
            Save Changes
          </Button>
        </Flex>

      </VStack>
    </LoadingPanel>
  )
}

export default EditPrayerCalculationView
