import Card from './Card'
import React, { useContext, useState } from 'react'
import {
  Heading, Text,
  VStack,
  Button,
} from '@chakra-ui/react'

import { AppContext } from '../providers/AppProvider'
import ReactGA from 'react-ga4'

function TestCallCard ({onComplete=() => null}) {

  const { postScheduleTestCall, showSuccessToast, showErrorToast } = useContext(
    AppContext)

  const [isLoading, setIsLoading] = useState(false);


  function scheduleTestCall() {
    setIsLoading(true);
    postScheduleTestCall().then(response => {
      showSuccessToast({ message: response?.data?.message })
      onComplete()
    }).catch(e => showErrorToast({ message: e.message }))
      .finally(() => setIsLoading(false))


    ReactGA.event({
      action: 'Clicked Schedule Test Call'
    })
  }

  return (
    <Card>
      <VStack width={'100%'} spacing={2} align={'start'} justify={'start'}>
        <Heading as={'h3'} size={'md'}>Schedule Test Call</Heading>
        <Text textAlign={'start'}>Schedule a test call to your phone, to see how it works. We'll call you within 5 minutes.</Text>
        <Button onClick={scheduleTestCall} isLoading={isLoading}>
          Schedule Call
        </Button>

      </VStack>
    </Card>
  )
}

export default TestCallCard
