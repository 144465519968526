import {
  Box,
  Container,
  Heading, Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

function LandingHadith() {
  return (
    <Stack
      justify="center"
      align={['center', 'center']}
      minHeight={'400px'}
      // bgImage={duaImage} bgSize={'cover'} width={'100%'} height={'400px'}
    >
      <Container
        maxW="6xl"
        position="relative"
        // bgColor={useColorModeValue('rgba(255,255,255,0.4)', 'rgba(0,0,0,0.6)')}
        // justifyContent={['center', 'center', 'right']} alignItems={'center'}
        // flex={1} display={'flex'} width={'100%'}
      >

        <Heading
          fontWeight={300}
          fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }}
          lineHeight="130%"
          zIndex={1}
          maxWidth={['100%', '100%', '700px']}
        >
          <VStack>
            <Text>
              مَنْ صَلَّى الصُّبْحَ فَهُوَ فِي ذِمَّةِ اللَّهِ عَزَّ وَجَلَّ
            </Text>
            <Text>
              <b>
                {' '}
                “Whoever offers the
                <Box
                  display="inline"
                  borderRadius="4px"
                  bg="red"
                  p="0 5px 3px 5px"
                  mx="5px"
                  // color={"gray.900"}
                  bgGradient={useColorModeValue('linear(to-r, yellow.200, yellow.400)', 'linear(to-r, blue.700, blue.900)')}
                >
                  morning prayer
                </Box>
                ,
                he is under the protection of Allah, the Mighty and Sublime.”
              </b>
            </Text>
            <Text fontSize="md">
              Sunan Ibn Majah 3946
            </Text>
          </VStack>
        </Heading>
      </Container>
    </Stack>
  );
}

export default LandingHadith;
