import Card from './Card'
import React, { useContext, useEffect, useState } from 'react'
import {
  Flex, Heading, VStack, Button, Text,
  FormControl, FormLabel, Input, FormHelperText, HStack, Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon, Spinner,
} from '@chakra-ui/react'

import { AppContext } from '../providers/AppProvider'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import { useNavigate } from 'react-router-dom'
import { FaPhone } from 'react-icons/fa';


dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.guess()

function CurrentFajrSettingsCard () {

  const [isFajrTimeCalculationLoading, setIsFajrTimeCalculationLoading] = useState(false)
  const [fajrTime, setFajrTime] = useState(null)
  const [fajrTimeError, setFajrTimeError] = useState(null)

  const {
    fetchUserFajrTime,
    user = null,
    isAuthenticated,
  } = useContext(AppContext)

  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      getTodayFajrTime()
    }
  }, [isAuthenticated])

  function getTodayFajrTime () {
    setFajrTimeError(null)
    setIsFajrTimeCalculationLoading(true)
    fetchUserFajrTime().
      then(response => {
        setFajrTime(response?.data?.result?.fajrTime)
      }).
      catch(e => setFajrTimeError(e.message)).
      finally(_ => setIsFajrTimeCalculationLoading(false))
  }

  return (
    <>
      <Card>

        <VStack width={'100%'} spacing={6} align={'start'}>
          <Flex justify={'space-between'} align={'center'} width={'100%'}>
            <Heading as={'h3'} size={'md'}>Fajr Settings</Heading>
          </Flex>

          {/* Fajr Time */}
          <VStack width={'100%'} align={'center'} justify={'center'}
                  spacing={3}>
            <Text fontWeight={'bold'}>Next Fajr call will be at:</Text>

            <Spinner display={isFajrTimeCalculationLoading ? 'block' : 'none'} />
            <VStack spacing={1} display={isFajrTimeCalculationLoading ? 'none' : 'block'}>

              <Text display={isFajrTimeCalculationLoading ? 'none' : 'block'}>
                {fajrTime == null || fajrTimeError ? fajrTimeError : fajrTime}
              </Text>
              <Text display={fajrTime == null || fajrTimeError ? 'none' : 'block'}
                    fontSize={'xs'}
              >
                Based on {user?.mosqueId ? user?.mosqueName : `${user?.cityName} ${user?.tzName}`}
              </Text>
            </VStack>

            <HStack flexWrap={'wrap'} gap={1} justify={'center'}>
              <Button onClick={() => navigate('/app/settings')} size={'sm'}>Change
                Fajr Settings</Button>
              <Button onClick={getTodayFajrTime}
                      size={'sm'}>Re-calculate</Button>
            </HStack>
          </VStack>

          {/* Fajr Time */}
          <VStack width={'100%'} align={'center'} justify={'center'}
                  spacing={3}>
            <Text fontWeight={'bold'}>We'll call you from</Text>
            <HStack color={'teal.500'}>
              <FaPhone/>
            <Link fontWeight={'bold'} href={`tel:${user?.fajrIoNumber}`}>
              {user?.fajrIoNumber}
            </Link>
            </HStack>
            <Text>Save us in your contacts as Fajr.io so you don't miss our calls!</Text>
          </VStack>

          <Accordion allowToggle w={'full'} display={'none'}>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Text flex='1' textAlign='left'>
                    View Calculation Details
                  </Text>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <VStack
                  width={'100%'} spacing={6} align={'start'}
                  display={user?.mosqueId ? 'flex' : 'none'}
                >
                  {/* City */}
                  <VStack width={'100%'} align={'start'}>
                    <FormControl align={'start'}>
                      <FormLabel htmlFor={'mosque'}>
                        Mosque
                      </FormLabel>

                      <Input type={'text'} value={user?.mosqueName} disabled={true}/>
                      <FormHelperText textAlign={'left'}>
                        We'll calculate our Fajr time based on your mosque timetable.
                      </FormHelperText>
                    </FormControl>
                  </VStack>

                </VStack>
                <VStack
                  width={'100%'} spacing={6} align={'start'}
                  display={user?.mosqueId ? 'none' : 'flex'}
                >

                  {/* Country */}
                  <VStack width={'100%'} align={'start'}>
                    <FormControl>
                      <FormLabel htmlFor={'country'}>
                        Country
                      </FormLabel>
                      <Input type={'text'} value={user?.countryName} disabled={true}/>
                      <FormHelperText textAlign={'left'}>We'll calculate our
                        Fajr time based on your country.</FormHelperText>
                    </FormControl>
                  </VStack>


                  {/*<Alert status='info' variant='solid'*/}
                  {/*       display={!user?.user_prefs?.city?.country?.is_supported*/}
                  {/*         ? 'flex'*/}
                  {/*         : 'none'}*/}
                  {/*       justify={'space-around'}*/}
                  {/*>*/}
                  {/*  <AlertIcon/>*/}
                  {/*  <Flex justify={'space-between'} align={'center'} flex={1}>*/}
                  {/*    <VStack align={'start'}>*/}
                  {/*      <AlertTitle fontSize={'md'}>Sorry, we've not reach you*/}
                  {/*        yet!</AlertTitle>*/}
                  {/*      <AlertDescription textAlign={'start'} fontSize={'sm'}>*/}
                  {/*        Your country is currently not supported by our*/}
                  {/*        platform. We'll be expanding our reach very soon,*/}
                  {/*        check back again soon!*/}
                  {/*      </AlertDescription>*/}
                  {/*    </VStack>*/}
                  {/*  </Flex>*/}
                  {/*</Alert>*/}

                  {/* City */}
                  <VStack width={'100%'} align={'start'}>
                    <FormControl align={'start'}>
                      <FormLabel htmlFor={'city'}>
                        City
                      </FormLabel>
                      <Input type={'text'} value={user?.cityName} disabled={true}/>
                      <FormHelperText textAlign={'left'}>
                        We'll calculate our Fajr time based on your city,
                        if your town isn't here please try the closest city to
                        you.
                      </FormHelperText>
                    </FormControl>
                  </VStack>

                  {/* Timezone */}
                  <VStack width={'100%'} align={'start'}>
                    <FormControl align={'start'}>
                      <FormLabel htmlFor={'timezone'}>
                        Timezone
                      </FormLabel>
                      <Input type={'text'} value={user?.tzName} disabled={true}/>
                      <FormHelperText textAlign={'left'}>
                        We'll calculate our Fajr time based on your city,
                        if your town isn't here please try the closest city to
                        you.
                      </FormHelperText>
                    </FormControl>
                  </VStack>

                  {/* Method */}
                  <VStack width={'100%'} align={'start'}>
                    <FormControl align={'start'}>
                      <FormLabel htmlFor={'method'}>
                        Prayer Calculation Method
                      </FormLabel>
                      <Input id={'method'} type={'text'}
                             value={user?.prayerCalcMethod} disabled={true}/>
                      <FormHelperText textAlign={'left'}>
                        We use these methods to calculate Fajr and Isha times, the
                        main difference is the angles used for calculations.
                      </FormHelperText>
                    </FormControl>
                  </VStack>

                  {/* High Lat */}
                  <VStack width={'100%'} align={'start'}>
                    <FormControl align={'start'}>
                      <FormLabel htmlFor={'method'}>
                        High Latitude Rule
                      </FormLabel>
                      <Input id={'method'} type={'text'} value={user?.prayerCalcHlat}
                             disabled={true}/>
                      <FormHelperText textAlign={'left'}>
                        In locations at higher latitude, twilight may persist
                        throughout the night during some months of the year.
                        In these abnormal periods, the determination of Fajr and Isha
                        is not possible using just the methods above.
                        To overcome this problem, several solutions have been
                        proposed.
                      </FormHelperText>
                    </FormControl>
                  </VStack>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

        </VStack>

      </Card>

    </>
  )
}

export default CurrentFajrSettingsCard
