import {
  Button,
  Heading, HStack,
  VStack, Image, Container,
} from '@chakra-ui/react';
import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'

function Subscriptions() {
  return (
    <Container py={5} maxW="container.lg" paddingTop="5vh">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fajr.io | Subscriptions</title>
      </Helmet>
      <VStack>
        {/*<Heading>Subscriptions</Heading>*/}
        <Outlet />
      </VStack>
    </Container>
  );
}

export default Subscriptions;
