import React from 'react';
import { Text } from '@chakra-ui/react';

class ErrorBoundary extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
    });
  }

  render() {
    if (this.state.hasError) {
      return <Text color="red.500">An error occurred</Text>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
