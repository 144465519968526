import {
  Container,
} from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../providers/AppProvider';
import LoginForm from '../components/LoginForm';
import ReactGA from 'react-ga4';
import {Helmet} from "react-helmet";

function SignInPage() {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.event({
      action: 'Sign In Page Opened'
    })
  }, [null])

  useEffect(() => {
    if (user) {
      navigate('/app');
    }
  }, [user]);

  return (
    <Container flex={1} paddingTop="10vh">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fajr.io | Sign In</title>
      </Helmet>
      <LoginForm showSignUp={false}/>
    </Container>
  );
}

export default SignInPage;
