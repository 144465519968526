import {
  FormControl,
  FormLabel,
  Heading, HStack,
  Tag, VStack, Link, Button, Text, useColorModeValue,
} from '@chakra-ui/react'
import Card from './Card'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../providers/AppProvider'
import UpgradePlanButton from './UpgradePlanButton'
import { premiumPlans } from './PricingView'
import ReactGA from 'react-ga4'

function AccountCard () {
  const [isCustomerPortalLoading, setIsCustomerPortalLoading] = useState(false)
  const [customerPortalUrl, setCustomerPortalUrl] = useState(null)

  const {
    showErrorToast, subscriptionViewPortal, user, isAuthenticated,
  } = useContext(AppContext)

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     fetchStripeSubscriptionPortalUrl()
  //   }
  // }, [isAuthenticated])

  function fetchStripeSubscriptionPortalUrl () {
    setIsCustomerPortalLoading(true)
    subscriptionViewPortal().
      then(response => {
        const url = response?.data?.result?.url
        if (url) {
          window.location.replace(url)
        }
      }).
      catch(e => showErrorToast({ message: e.message })).
      finally(_ => setIsCustomerPortalLoading(false))

    ReactGA.event({
      action: 'Clicked Manage Your Subscription'
    })
  }

  return (
    <>
      <Card>
        <VStack width={'100%'} spacing={4} align={'start'}>
          <Heading as={'h3'} size={'md'}>Account Details</Heading>

          {/* ID */}
          <VStack width={'100%'} align={'start'}>
            <FormControl>
              <FormLabel htmlFor={'userId'} fontWeight={'bold'}>
                Your ID
              </FormLabel>
              <Text textAlign={'left'} color={useColorModeValue('gray.700', 'gray.200')}>{user?.id}</Text>
              {/*<Input type={'text'} id={'userId'}*/}
              {/*       value={user?.id} disabled={true}/>*/}
            </FormControl>
          </VStack>

          {/* Number */}
          <FormControl>
            <FormLabel htmlFor={'phoneNumber'} fontWeight={'bold'}>
              Your Number
            </FormLabel>
            <Text textAlign={'left'} color={useColorModeValue('gray.700', 'gray.200')}>
              {user?.phoneNumber}
            </Text>

            <Text textAlign={'left'} fontSize={'sm'} color={useColorModeValue('gray.500', 'gray.500')}>Edit number feature coming soon!</Text>

          </FormControl>
          {/*<VerifyPhoneAlert />*/}


          <FormControl align={'start'}>
            <FormLabel htmlFor={'user_plan'} fontWeight={'bold'}>
              Your Current Plan
            </FormLabel>
            <Text>{user?.subscriptionPlanName ? user?.subscriptionPlanName : 'FREE TRIAL'}</Text>
          </FormControl>


            <Button
              // href={customerPortalUrl}
              size={'sm'}
              variant={'solid'}
              display={user?.subscriptionPlanName ? 'block' : 'none'}
              fontWeight={'bold'}
              isLoading={isCustomerPortalLoading}
              onClick={fetchStripeSubscriptionPortalUrl}
            >
              Manage Your Subscription
            </Button>

            <UpgradePlanButton colorScheme={'teal'} />


        </VStack>
      </Card>


    </>
  )
}

export default AccountCard
