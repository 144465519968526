import { createContext, useEffect, useState, useRef } from 'react'
import axios from 'axios'
import config from '../config'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

export const AppContext = createContext({
  doSignIn: async (number, password) => null,
  doSignUp: async (number, password) => null,
  doSignOut: async () => null,
  sendForgetPasswordResetCode: async (phoneNumber) => null,
  postResetPassword: async (phoneNumber, code, password) => null,
  deleteAccount: async (password) => null,
  fetchCurrentUser: () => null,
  fetchValidatePhoneNumber: async (phoneNumber) => new Promise(null),
  fetchSendPhoneVerification: async () => new Promise(null),
  postVerifyCode: async (code) => new Promise(null),
  fetchUserFajrTime: async () => new Promise(null),
  fetchFajrTimeSchedule: async () => new Promise(null),
  deleteFajrTimeSchedule: async () => new Promise(null),
  fetchPrayerCalcData: async () => new Promise(null),
  fetchStaticData: async () => new Promise(null),
  postMakeMosqueRequest: async (name, address) => new Promise(null),
  fetchDataCities: async (countryCode, city) => new Promise(null),
  updatePrefs: async (prefs) => new Promise(null),
  updatePrefsToggles: async (prefs) => new Promise(null),
  subscribeToPremium: async (type) => new Promise(null),
  subscribeToPremiumSuper: async () => new Promise(null),
  subscriptionViewPortal: async () => new Promise(null),
  fetchStripeSession: async (sessionId) => new Promise(null),
  postScheduleTestCall: async () => new Promise(null),
  postFeedback: async (rating, message) => new Promise(null),
  postFeedbackHelp: async (email, details) => new Promise(null),
  fetchCallsStats: async () => new Promise(null),
  getAxiosInstance: () => null,
  showSuccessToast: ({ title, message }) => null,
  showErrorToast: ({ title, message }) => null,
  showGoPremium: false,
  setShowGoPremium: () => null,
  user: null,
  isNative: false,
  nativeSendMessage: (type, data) => null,
  isUserLoading: true,
  isAuthenticated: false,
  isPlusUser: false,
  isPremiumUser: false,
  token: null,
  isStaticLoading: false,
  staticPrices: {},
  setUser: () => null,
})

export function AppProvider (props) {

  const [user, setUser] = useState(null)
  const [isUserLoading, setIsUserLoading] = useState(true)
  const [token, setToken] = useState(null)
  const [hasLoadedToken, setHasLoadedToken] = useState(false)
  const [showGoPremium, setShowGoPremium] = useState(false)
  const [prices, setPrices] = useState({})
  const [isStaticLoading, setIsStaticLoading] = useState(false)
  const tawkMessengerRef = useRef()
  // const [isNativeApp, setIsNativeApp] = useState(false)

  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    setIsStaticLoading(true)
    fetchStaticData().then(response => {
      setPrices(response?.data?.result?.prices);
    })
      .finally(() => setIsStaticLoading(false))
  }, [user])

  useEffect(() => {
    console.log(`Fetching token ${token}`)
    setToken(localStorage.getItem('fajrio_token'))
    setHasLoadedToken(true)
  }, [])

  useEffect(() => {
    if (token) {
      console.log(`Updating token ${token}`)
      localStorage.setItem('fajrio_token', token)
    }
  }, [token])

  //---------------------------------------------------------------
  // User/Account Endpoints
  //---------------------------------------------------------------

  async function doSignUp (number, password) {
    const response = await _getAxiosInstance().
      post(`${config.apiUrl}/users/signup?source=web`, {
        phoneNumber: number,
        password: password,
      }).catch(_handleAxiosRequestError)

    const data = _getDataOrThrow(response)
    const result = data?.result
    _setUserAndToken(result?.user, result?.token)
    return result?.user
  }

  async function doSignIn (number, password) {
    const response = await _getAxiosInstance().
      post(`${config.apiUrl}/users/signin`, {
        phoneNumber: number,
        password: password,
      }).catch(_handleAxiosRequestError)

    const data = _getDataOrThrow(response)
    const result = data?.result
    _setUserAndToken(result?.user, result?.token)
    return result?.user
  }

  async function sendForgetPasswordResetCode (phoneNumber) {
    return await _getAxiosInstance().
      post(`${config.apiUrl}/users/forget-password`, {
        phoneNumber
      }).catch(_handleAxiosRequestError)
  }

  /**
   * Reset password and verify reset code
   * @param phoneNumber
   * @param code
   * @param password
   * @returns {Promise<AxiosResponse<any>|void>}
   */
  async function postResetPassword (phoneNumber, code, password) {
    return await _getAxiosInstance().
      post(`${config.apiUrl}/users/forget-password/reset`, {
        phoneNumber, code, password
      }).catch(_handleAxiosRequestError)
  }

  async function deleteAccount (password) {
    return await _getAxiosInstance().
      post(`${config.apiUrl}/users/goodbye`, {
        password: password,
      }).catch(_handleAxiosRequestError)
  }

  async function doSignOut () {
    setUser(null)
    setToken(null)
    localStorage.setItem('fajrio_token', null)
  }

  function fetchCurrentUser () {
    if (hasLoadedToken) {
      setIsUserLoading(true);
      _getAxiosInstance().
        get(`${config.apiUrl}/users/current-user`).
        then(response => {
          const data = _getDataOrThrow(response)
          const { user } = { ...data?.result }
          setUser(user)
          if (user == null) {
            setToken(null)
            // navigate("/login")
          }
        }).
        catch(e => {
          // showErrorToast({message: "Failed to authenticate"})
          setUser(null)
          setToken(null)
          // navigate("/login")
        }).finally(() => setIsUserLoading(false))

    }
  }

  async function fetchValidatePhoneNumber (phoneNumber) {
    return _getAxiosInstance().
      post(`${config.apiUrl}/users/validate-phone-number`,
        {
          phoneNumber: phoneNumber,
        },
      ).catch(_handleAxiosRequestError)
  }

  async function fetchSendPhoneVerification () {
    return _getAxiosInstance().
      post(`${config.apiUrl}/users/send-verification`).
      catch(_handleAxiosRequestError)
  }

  async function postVerifyCode (code) {
    return _getAxiosInstance().
      post(`${config.apiUrl}/users/verify-phone-code`, {
        code: code,
      }).catch(_handleAxiosRequestError)
  }

  //---------------------------------------------------------------
  // Salah Schedules
  //---------------------------------------------------------------

  async function fetchUserFajrTime () {
    return _getAxiosInstance().
      get(`${config.apiUrl}/salah-schedules/fajr-schedules/today`).
      catch(_handleAxiosRequestError)
  }

  async function fetchFajrTimeSchedule () {
    return _getAxiosInstance().
      get(`${config.apiUrl}/salah-schedules/fajr-schedules`).
      catch(_handleAxiosRequestError)
  }

  async function deleteFajrTimeSchedule () {
    return _getAxiosInstance().
      delete(`${config.apiUrl}/salah-schedules/fajr-schedules`).
      catch(_handleAxiosRequestError)
  }

  async function updatePrefs (data) {
    return await _getAxiosInstance().
      post(`${config.apiUrl}/salah-schedules/fajr-prefs`, data)
      .catch(_handleAxiosRequestError)
  }

  async function updatePrefsToggles (data) {
    return await _getAxiosInstance().
      post(`${config.apiUrl}/salah-schedules/fajr-prefs/toggles`, data)
      .catch(_handleAxiosRequestError)
  }

  async function postScheduleTestCall () {
    return await _getAxiosInstance().
      post(`${config.apiUrl}/salah-schedules/calls/test`)
      .catch(_handleAxiosRequestError)
  }

  async function fetchCallsStats () {
    return await _getAxiosInstance().
      get(`${config.apiUrl}/salah-schedules/calls/stats`)
      .catch(_handleAxiosRequestError)
  }

  //---------------------------------------------------------------
  // Subscription Endpoints
  //---------------------------------------------------------------

  async function subscribeToPremium (type) {
    return await _getAxiosInstance().
      post(`${config.apiUrl}/subscriptions/premium/${type}`, {})
      .catch(_handleAxiosRequestError)
  }

  async function subscribeToPremiumSuper () {
    return await _getAxiosInstance().
      post(`${config.apiUrl}/subscriptions/premium/PREMIUM`, {})
      .catch(_handleAxiosRequestError)
  }

  async function subscriptionViewPortal() {
    return await _getAxiosInstance().
      post(`${config.apiUrl}/subscriptions/view-portal`, {})
      .catch(_handleAxiosRequestError)
  }

  async function fetchStripeSession (sessionId) {
    return await _getAxiosInstance().
      get(`${config.apiUrl}/subscriptions/session/${sessionId}`, {})
      .catch(_handleAxiosRequestError)
  }

  //---------------------------------------------------------------
  // Data/Misc Endpoints
  //---------------------------------------------------------------

  async function fetchPrayerCalcData () {
    return await _getAxiosInstance().
      get(`${config.apiUrl}/data/prayer-calc`).catch(_handleAxiosRequestError)
  }

  async function fetchStaticData () {
    return await _getAxiosInstance().
      get(`${config.apiUrl}/data/static`).catch(_handleAxiosRequestError)
  }

  async function fetchDataCities (countryCode, city) {
    return await _getAxiosInstance().
      get(`${config.apiUrl}/data/cities/${countryCode}`, {
        params: {
          city: city
        }
      }).
      catch(_handleAxiosRequestError)
  }

  async function postMakeMosqueRequest (name, address) {
    return await _getAxiosInstance().
      post(`${config.apiUrl}/data/mosques/request`, {
        name:name,
        address: address
      })
      .catch(_handleAxiosRequestError)
  }

  async function postFeedback (rating, comments) {
    return await _getAxiosInstance().
      post(`${config.apiUrl}/data/feedback`, {
        rating, comments
      })
      .catch(_handleAxiosRequestError)
  }

  async function postFeedbackHelp (email, details) {
    return await _getAxiosInstance().
      post(`${config.apiUrl}/data/feedback/contact`, {
        email, details
      })
      .catch(_handleAxiosRequestError)
  }

  //---------------------------------------------------------------
  // Axios
  //---------------------------------------------------------------

  function _handleAxiosRequestError (e) {
    const response = e.response

    if (response) {
      const errorMessage = response?.data?.error ||
        'Failed to process your request'
      throw new Error(errorMessage)
    }
  }

  function _getAxiosInstance () {
    return axios.create({
      timeout: 15000,
      headers: {
        common: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      },
    })
  }

  //---------------------------------------------------------------
  // Toasts
  //---------------------------------------------------------------

  function showSuccessToast ({ title = 'Success', message = '' }) {
    _showToast(title, message, 'success')
  }

  function showErrorToast ({ title = 'Error', message = '' }) {
    _showToast(title, message, 'error')
  }

  function _showToast (title, message, type) {
    toast({
      title: title,
      description: message,
      status: type,
      duration: 3000,
      variant: 'solid',
      isClosable: true,
    })
  }

  function _setUserAndToken (user, token) {
    setUser(user)
    setToken(token)
  }

  function _getDataOrThrow (response) {
    const data = response?.data

    if (data) {
      if (response.status === 200) {
        return data
      } else {
        throw Error(data?.error || 'Failed to process your request')
      }
    } else {
      throw Error('Failed to process your request')
    }
  }

  //---------------------------------------------------------------
  // Navigation
  //---------------------------------------------------------------

  //---------------------------------------------------------------
  // Native
  //---------------------------------------------------------------

  function nativeSendMessage(type, data) {
    if (window.isNativeApp) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        type: type,
        data: data
      }))
    }
  }

  return (
    <AppContext.Provider value={{
      doSignUp: doSignUp,
      doSignIn: doSignIn,
      doSignOut: doSignOut,
      sendForgetPasswordResetCode: sendForgetPasswordResetCode,
      postResetPassword: postResetPassword,
      deleteAccount: deleteAccount,
      fetchCurrentUser: fetchCurrentUser,
      fetchValidatePhoneNumber: fetchValidatePhoneNumber,
      fetchSendPhoneVerification: fetchSendPhoneVerification,
      postVerifyCode: postVerifyCode,
      fetchUserFajrTime: fetchUserFajrTime,
      fetchFajrTimeSchedule: fetchFajrTimeSchedule,
      deleteFajrTimeSchedule: deleteFajrTimeSchedule,
      fetchPrayerCalcData: fetchPrayerCalcData,
      fetchStaticData: fetchStaticData,
      postMakeMosqueRequest: postMakeMosqueRequest,
      fetchDataCities: fetchDataCities,
      updatePrefs: updatePrefs,
      updatePrefsToggles: updatePrefsToggles,
      subscribeToPremium: subscribeToPremium,
      subscribeToPremiumSuper: subscribeToPremiumSuper,
      subscriptionViewPortal: subscriptionViewPortal,
      fetchStripeSession: fetchStripeSession,
      postScheduleTestCall: postScheduleTestCall,
      postFeedback: postFeedback,
      postFeedbackHelp: postFeedbackHelp,
      fetchCallsStats: fetchCallsStats,
      getAxiosInstance: _getAxiosInstance(),
      showSuccessToast: showSuccessToast,
      showErrorToast: showErrorToast,
      tawkMessengerRef,
      showGoPremium,
      setShowGoPremium,
      user: user,
      isNative: window?.isApp || false,
      nativeSendMessage: nativeSendMessage,
      isUserLoading: isUserLoading,
      token: token,
      staticIsStaticLoading: isStaticLoading,
      staticPrices: prices,
      isAuthenticated: user != null && token != null,
      isPlusUser: user != null && (user?.subscriptionPlanName === 'PLUS' || user?.subscriptionPlanName === 'PREMIUM'),
      isPremiumUser: user != null && user?.subscriptionPlanName === 'PREMIUM',
    }}>
      {props.children}
    </AppContext.Provider>
  )
}
