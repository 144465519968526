import {
  Button,
  FormControl,
  FormLabel,
  Divider,
  Input,
  VStack,
  HStack,
} from '@chakra-ui/react'
import { FaHome } from 'react-icons/fa'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../providers/AppProvider'
import ResetPasswordDrawer from './ResetPasswordDrawer'
import PhoneNumberInput from './PhoneNumberInput'
import ReactGA from 'react-ga4';

function LoginForm ({showSignUp=true}) {
  const [isLoading, setIsLoading] = useState(false)
  const [isSignUp, setIsSignUp] = useState(showSignUp)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [countryCode, setCountryCode] = useState('44')

  const [isForgetPassword, setIsForgetPassword] = useState(false)

  const {
    doSignIn,
    doSignUp,
    showErrorToast,
    showSuccessToast,
  } = useContext(AppContext)
  const navigate = useNavigate()

  function toggleSignUp () {
    setIsSignUp((prev) => !prev)
  }

  function signIn () {
    if (isSignUp) {
      ReactGA.event({
        action: 'Clicked Sign Up'
      })
      if (phoneNumber == null || phoneNumber.length < 5) {
        showErrorToast({ message: 'Please specify a phone number' })
      } else if (password == null) {
        showErrorToast({ message: 'Please enter a valid password' })
      } else if (passwordConfirm == null) {
        showErrorToast({ message: 'Please enter a valid password' })
      } else if (password !== passwordConfirm) {
        showErrorToast({ message: 'Passwords do not match' })
      } else {
        setIsLoading(true)
        doSignUp(phoneNumber, password).
          then((response) => {
            showSuccessToast({ message: 'Signed up successfully' })
            navigate('/app')
          })
          .catch((e) => {
            showErrorToast({ message: e.message })
            ReactGA.event({
              action: `Sign Up Error: ${e.message}`
            })
          })
          .finally((_) => setIsLoading(false))
      }
    } else {
      ReactGA.event({
        action: 'Clicked Sign In'
      })
      setIsLoading(true)
      doSignIn(phoneNumber, password).
        then((response) => {
          showSuccessToast({ message: 'Authenticated successfully' })
          navigate('/app')
        }).
        catch((e) => {
          showErrorToast({ message: e.message })
          ReactGA.event({
            action: `Sign In Error: ${e.message}`
          })
        }).
        finally((_) => setIsLoading(false))
    }
  }

  function goHome () {
    navigate('/')
  }

  function onPasswordChange (event) {
    setPassword(event.target.value)
  }

  function onPasswordConfirmChange (event) {
    setPasswordConfirm(event.target.value)
  }

  function onCountryChange(event) {
    setCountryCode(event.target.value)
  }

  return (
    <>
      <VStack spacing={4}>
        <FormControl>
          <FormLabel htmlFor="tel">Phone Number</FormLabel>
          <PhoneNumberInput
            isLoading={isLoading}
            onChange={setPhoneNumber}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="tel">Password</FormLabel>
          <Input
            id="password"
            type="password"
            placeholder="**********"
            disabled={isLoading}
            onChange={onPasswordChange}
          />
        </FormControl>
        <FormControl display={isSignUp ? 'block' : 'none'}>
          <FormLabel htmlFor="tel">Confirm Password</FormLabel>
          <Input
            id="password-conf"
            type="password"
            placeholder="**********"
            disabled={isLoading}
            onChange={onPasswordConfirmChange}
          />
        </FormControl>
        <HStack width={'100%'} paddingBottom={5} justify={'center'} paddingTop={2} spacing={4}>
          <Button width={['40%']} leftIcon={<FaHome/>}  onClick={goHome}>
            Home
          </Button>
          <Button width={['60%', '60%']} colorScheme={'teal'} isLoading={isLoading} onClick={signIn}>
            {isSignUp ? 'Sign Up' : 'Sign In'}
          </Button>
        </HStack>
        <Button
          variant="link"
          colorScheme="blue"
          size="sm"
          onClick={toggleSignUp}
        >
          {isSignUp ? 'I already have an account' : 'I\'m a new user'}
        </Button>
        <Divider/>
        <Button
          variant="link"
          colorScheme="blue"
          size="sm"
          onClick={() => setIsForgetPassword(true)}
        >
          Forgot password?
        </Button>
      </VStack>

      <ResetPasswordDrawer isOpen={isForgetPassword}
                           onClose={() => setIsForgetPassword(false)}/>
    </>
  )
}

export default LoginForm
