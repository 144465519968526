import React, { useContext } from 'react'
import { AppContext } from '../providers/AppProvider'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Flex,
  VStack,
} from '@chakra-ui/react'
import UpgradePlanButton from './UpgradePlanButton'

const FreeTrialEndAlert = () => {
  const {
    user,
  } = useContext(AppContext)

  const hasFreeTrialEnded = user?.freeTrialDaysRemaining < 0;
  return (
    <Alert status={hasFreeTrialEnded ? "error" : 'warning'} variant="solid"
           display={user?.isFree ? 'flex' : 'none'}
           justify={'space-around'}
    >
      <AlertIcon/>
      <Flex justify={'space-between'} align={'center'} flex={1}>
        <VStack align={'start'}>
          {/*<AlertTitle fontSize={'md'}>Verify your*/}
          {/*  number!</AlertTitle>*/}
          <AlertDescription textAlign={'start'} fontSize={'sm'}>
            {hasFreeTrialEnded ? `Your free trial has ended, please upgrade your plan`
              : `Your free trial will end in ${user?.freeTrialDaysRemaining} days`
            }

          </AlertDescription>
        </VStack>
        <UpgradePlanButton color={'black'} size={'sm'}/>
      </Flex>
    </Alert>
  )
}


export default FreeTrialEndAlert
