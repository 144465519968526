import { Box, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import ErrorBoundary from './ErrorBoundary'

function Card(props) {
  return (
    <Box
      w={'full'}
      bg={useColorModeValue('white', 'gray.900')}
      boxShadow={'xl'}
      rounded={'md'}
      overflow={'hidden'}
      padding={["15px 20px", "15px 25px 20px 25px"]}
      {...props}
    >
      <ErrorBoundary>
        {props?.children}
      </ErrorBoundary>
    </Box>
  )
}
export default Card;
