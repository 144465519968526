import React, { useContext } from 'react'
import { AppContext } from '../providers/AppProvider'
import { Button } from '@chakra-ui/react'
import ReactGA from 'react-ga4'

const UpgradePlanButton = (props) => {
  const {
    user, setShowGoPremium
  } = useContext(AppContext)

  return (
    <Button display={user?.subscriptionPlanName ? 'none' : 'inline-flex'}
      // variant={'solid'}
      // bg={useColorModeValue('white', 'gray.700')}
      // color={useColorModeValue('black', 'white')}
            onClick={() => {
              setShowGoPremium(true)

              ReactGA.event({
                action: 'Clicked Upgrade'
              })
            }}
            {...props}
    >
      Upgrade
    </Button>
  )
}

export default UpgradePlanButton;
