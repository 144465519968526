import {
  Box,
  Button,
  ChakraProvider,
  Container,
  FormControl,
  FormLabel,
  Grid, GridItem, SimpleGrid,
  Heading, HStack,
  Input, LinkBox, LinkOverlay,
  VStack, Image,
  Flex, Text, Stack, Link, useColorModeValue, createIcon,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import fajrAlarmMeme from './fajrAlarmMeme.png';
import kermitHoodMeme from './kermitHoodMeme.png';
import sonWakeUpFajrMeme from './sonWakeUpFajrMeme.png';
import oneRingMeme from './oneRing.png';

function OurStoryPage() {
  const navigate = useNavigate();
  return (
    <VStack spacing={8} flex={1}>
      {/* <Flex backgroundImage={null} bgSize={"cover"} height={"40vh"} */}
      {/*           display={"flex"} flex={1} */}
      {/*      minHeight={"40vh"} width={"100%"} */}
      {/* > */}
      {/* </Flex> */}
      <Container maxW="3xl" position="relative">

        <Stack
          as={Box}
          textAlign="center"
          spacing={{ base: 8, md: 14 }}
          pt={{ base: 20, md: 36 }}
          pb={{ base: 20 }}
          zIndex={1}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            zIndex={1}
          >
            Why we built
            <Text as="span" color="teal.400" bgGradient="linear(to-r, teal.400, teal.600)" backgroundClip="text"> Fajr.io</Text>
          </Heading>
        </Stack>
        <Stack spacing={6} align="center" textAlign={'justify'}>

          <Text fontSize="xl">
            It all started back when we were kids. Life was easier then, no responsibilities, no work, no exams no stress.
            All we had to do was eat, sleep, and do whatever else was needed. Waking up for Fajr used go something like this...
          </Text>
          <Image src={sonWakeUpFajrMeme} height="auto" width="400px" />
          <Text fontSize="xl">
            As we got older it was harder to wake up for fajr, our parents were too busy and we were too old.
            Heck it was even harder to go to mosque for Fajr... how can you remember when Jama'ah time changed at mosque.
            So our Fajr alarms were something like this.
          </Text>
          <Image src={fajrAlarmMeme} height="auto" width="400px" />
          <Text fontSize="xl">
            Alarms work great, and if it works for you then perfect. However sometimes
            we forgot to set our Alarm or sometimes the mosque Fajr times would change.
            <br/>
            So we want to replace all those crazy alarms with <b>a single service...</b> <br/>
            <b>one service...</b>  <br/>
            <b>one service to rule them all...</b>  <br/>
            (without any worry for mosque Fajr times changing)
          </Text>
          <Image src={oneRingMeme} height="auto" width="400px" />
          <Text fontSize="xl">
            The only thing left is to stop Shaytaan from telling you to hang up
            and go back to bed... but that's something we can't help you with... yet...
          </Text>
          <Image src={kermitHoodMeme} height="auto" width="400px" />
          <Text fontSize="xl">
            Anyway, check us out and let us know if <b>Fajr.io</b> works for you!
          </Text>


          <Button
            colorScheme="teal"
            bg="teal.400"
            rounded="full"
            px={6}
            onClick={() => navigate('signin')}
            _hover={{
              bg: 'teal.500',
            }}
          >
            Try Fajr.io for free!
          </Button>
        </Stack>
      </Container>

    </VStack>
  );
}

export default OurStoryPage;
