import {
  Container,
  Stack,
  Heading, HStack,
} from '@chakra-ui/react'
import React from 'react';
import EditPrayerCalculationView
  from '../components/EditPrayerCalculationView';
import ErrorBoundary from '../components/ErrorBoundary';
import FreeTrialEndAlert from '../components/FreeTrialEndAlert'
import { FajrioIcon } from './AppContainer'
import { Helmet } from 'react-helmet'

function FajrSettingsPage() {
  return (
    <Container py={5} px={0} maxW="container.lg" paddingTop="5vh">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fajr.io | Settings</title>
      </Helmet>

      <Stack spacing={8} align={'center'}>

        <HStack spacing={4} px={4} align={'start'}>
          <FajrioIcon/>
          <Heading textAlign={'left'} as="h2">Fajr Call Settings & Prayer Calculations</Heading>
        </HStack>

        <FreeTrialEndAlert />

        <ErrorBoundary>
          <EditPrayerCalculationView />
        </ErrorBoundary>
      </Stack>
    </Container>
  );
}

export default FajrSettingsPage;
