import {
  Button,
  Flex,
  Box,
  HStack, VStack,
  Table,
  Text,
  TableContainer, Tbody, Td, Th, Thead, Tr, Spinner,
  Tag,
  TagLabel,
  TagLeftIcon,
  IconButton, useColorModeValue, AlertIcon, AlertDescription, Alert,
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../providers/AppProvider'
import { useNavigate } from 'react-router-dom'
import { FaCircle } from 'react-icons/fa'
import { AiOutlineTable, AiOutlinePicCenter, AiOutlineSetting } from 'react-icons/ai'
import { PhoneIcon, SunIcon } from '@chakra-ui/icons'
import ReactGA from 'react-ga4'

const FajrSchedule = () => {

  const [isLoading, setIsLoading] = useState(false)
  const [fajrTimes, setFajrTimes] = useState([])
  const [showTable, setShowTable] = useState(false)

  const {
    showErrorToast, showSuccessToast, fetchFajrTimeSchedule, user,
    deleteFajrTimeSchedule,
  } = useContext(
    AppContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (user != null) {
      fetchFajrTimes()
    }
  }, [user])


  useEffect(() => {
    if (showTable) {
      ReactGA.event({
        action: 'Fajr Schedule: Show Table View'
      })
    } else {
      ReactGA.event({
        action: 'Fajr Schedule: Show Timeline View'
      })
    }
  }, [showTable])

  function fetchFajrTimes () {
    setIsLoading(true)
    fetchFajrTimeSchedule().
      then(response => setFajrTimes(response?.data?.result || [])).
      catch(e => showErrorToast({message: e.message})).
      finally(_ => setIsLoading(false))
  }

  function onClearCallScheulde () {
    setIsLoading(true)
    deleteFajrTimeSchedule().
      then(response => {
        showSuccessToast({ message: response?.data?.result?.message })
        fetchFajrTimes()
      }).
      catch(e => showErrorToast({ message: e.message })).
      finally(_ => setIsLoading(false))
  }

  function toggleShowTable () {
    setShowTable(v => !v)
  }

  function gotToSettings () {
    navigate('/app/settings')
  }

  const isFajrTimesEmpty = fajrTimes?.length < 1
  const shouldShowData = !isLoading && !isFajrTimesEmpty

  return (
    <VStack spacing={6} width={'100%'} align={'start'} px={3} py={3}
            paddingBottom={'100px'}>
      <Flex justify={'space-between'} align={'center'} width={'100%'}
            flexWrap={'wrap'} gap={2}>
        <HStack>
          <Button
            leftIcon={<AiOutlineSetting />} onClick={gotToSettings}
          >Edit Fajr Settings
          </Button>
        </HStack>
        <HStack>
          {/*<Button size={'sm'} onClick={fetchFajrTimes} isLoading={isLoading}>Refresh</Button>*/}
          {/*<Button onClick={onClearCallScheulde} isLoading={isLoading}>Delete Schedule</Button>*/}
          <IconButton
            icon={showTable ? <AiOutlinePicCenter/> : <AiOutlineTable/>}
            onClick={toggleShowTable}/>
        </HStack>
      </Flex>

      {/*<TestCallCard onComplete={fetchFajrTimes} />*/}


      <Spinner color={'teal.500'}
               display={isLoading ? 'block' : 'none'}
      />

      <Alert status={'info'} variant="solid"
             display={isLoading || shouldShowData ? 'none' : 'flex'}
             justify={'space-around'}
      >
        <AlertIcon/>
        <Flex justify={'space-between'} align={'center'} flex={1}>
          <VStack align={'start'}>
            {/*<AlertTitle fontSize={'md'}>Verify your*/}
            {/*  number!</AlertTitle>*/}
            <AlertDescription textAlign={'start'} fontSize={'sm'}>
              You have no Fajr calls scheduled
            </AlertDescription>
          </VStack>
        </Flex>
      </Alert>



      <VStack spacing={1} width={'100%'}
              display={shouldShowData && !showTable ? 'flex' : 'none'}>
        {fajrTimes?.map(ft => <TimeLineCard fajrSchedule={ft}/>)}
      </VStack>

      <TableContainer width={'100%'}
                      display={shouldShowData && showTable ? 'block' : 'none'}>
        <Table variant="striped" size={'sm'}>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Call Time</Th>
              <Th>{`${user?.mosqueId ? 'Fajr Jama\'ah' : `Fajr Time`}`}</Th>
              <Th>Sunrise Time</Th>
            </Tr>
          </Thead>

          <Tbody>
            {fajrTimes?.map(ft => {
                const date = ft.fajrCallDate
                return (
                  <Tr key={date}>
                    <Td>{date}</Td>
                    <Td>{ft.fajrCallTimeIso}</Td>
                    <Td>{ft.fajrTimeIso}</Td>
                    <Td>{ft.sunriseTimeIso}</Td>
                  </Tr>)
              },
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  )
}

const TimeLineCard = ({ fajrSchedule }) => {

  const {
    user,
  } = useContext(AppContext)

  const heights = ['120px', '120px']

  return (
    <Box height={'100%'} width={'100%'} position={'relative'} pl={7}>
      <HStack height={'100%'} spacing={4}>
        {/* Timeline Part */}
        <VStack spacing={1} width={'10px'} height={heights} align={'center'}
                pt={1} color={useColorModeValue('gray.200', 'gray.700')}>
          <FaCircle/>
          <Box flex={1} borderColor={useColorModeValue('gray.200', 'gray.700')} borderWidth={'1px'}
               borderStyle={'solid'} width={'1px'} height={'80px'}/>
        </VStack>

        {/*  Content */}
        <VStack flex={1} width={'100%'} height={heights} align={'start'}>
          <Text>{fajrSchedule?.fajrCallDate}</Text>
          <VStack align={'start'}>
            <HStack width={'100%'} flexWrap={'wrap'} gap={1} align={'start'}>
              <VStack align={'start'} spacing={0}>
                {/*<Text fontWeight={'bold'} fontSize={'sm'}>Call Time</Text>*/}
                <Tag colorScheme="teal" variant={'solid'}>
                  <TagLeftIcon boxSize="12px" as={PhoneIcon}/>
                  <TagLabel>{fajrSchedule?.fajrCallTimeIso}</TagLabel>
                </Tag>
              </VStack>

              <VStack align={'start'} spacing={0}>
                {/*<Text fontWeight={'bold'} fontSize={'sm'}>Fajr</Text>*/}
                <Tag variant={'solid'}>
                  {/*<TagLeftIcon boxSize="12px" as={TimeIcon}/>*/}
                  <TagLabel>{`${user?.mosqueId ? 'Fajr Jama\'ah' : `Fajr`} ${fajrSchedule?.fajrTimeIso}`}</TagLabel>
                </Tag>
              </VStack>


              <VStack align={'start'} spacing={0} display={user?.mosqueId ? 'none' : 'flex'}>
                {/*<Text fontWeight={'bold'} fontSize={'sm'}>Sunrise</Text>*/}
                <Tag colorScheme={'yellow'} variant={'solid'}>
                  <TagLeftIcon boxSize="12px" as={SunIcon}/>
                  <TagLabel>{fajrSchedule?.sunriseTimeIso}</TagLabel>
                </Tag>
              </VStack>

            </HStack>

            <Text textAlign={'left'} fontSize={'xs'}>We'll call you at {fajrSchedule?.fajrCallTimeIso}. Fajr starts at {fajrSchedule?.fajrTimeIso}.</Text>

          </VStack>
        </VStack>
      </HStack>
    </Box>
  )
}

export default FajrSchedule
