import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent, DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  HStack,
  Input,
  PinInput, PinInputField,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { AppContext } from '../providers/AppProvider';

function ResetPasswordDrawer({ isOpen, onClose, defaultPhoneNumber }) {
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isForgetPasswordCodeSent, setIsForgetPasswordCodeSent] = useState(false);

  const [isResetCodeValied, setIsResetCodeValied] = useState(false);
  const [resetCode, setResetCode] = useState(null);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const {
    doSignIn, doSignUp, showErrorToast, showSuccessToast, sendForgetPasswordResetCode,
    postResetPassword,
  } = useContext(AppContext);
  const navigate = useNavigate();

  function sendResetCode(event) {
    setIsForgetPasswordCodeSent(false);
    setIsLoading(true);
    sendForgetPasswordResetCode(phoneNumber)
      .then((resp) => setIsForgetPasswordCodeSent(true))
      .catch((e) => showErrorToast({ message: e.message }))
      .finally(() => setIsLoading(false));
  }

  function onResetCodeComplete(resetCode) {
    setIsResetCodeValied(false);
    setIsLoading(true);
    postResetPassword(phoneNumber, resetCode)
      .then((resp) => {
        setIsResetCodeValied(true);
        setResetCode(resetCode);
      })
      .catch((e) => showErrorToast({ message: e.message }))
      .finally(() => setIsLoading(false));
  }

  function updatePassword() {
    if (confirmPassword == newPassword) {
      setIsLoading(true);
      postResetPassword(phoneNumber, resetCode, newPassword)
        .then((resp) => {
          showSuccessToast({ message: 'Password change complete' });
          navigate('/');
        })
        .catch((e) => showErrorToast({ message: e.message }))
        .finally(() => setIsLoading(false));
    } else {
      showErrorToast({ message: 'Passwords do not match' });
    }
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement="bottom"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent maxHeight="100%">
        <DrawerCloseButton />
        <DrawerHeader>Reset your password</DrawerHeader>

        <DrawerBody>
          <Container maxW="3xl" position="relative">

            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="teal.500"
              size="xl"
              display={isLoading ? 'block' : 'none'}
            />

            <VStack spacing={6} display={isLoading || (isForgetPasswordCodeSent && isResetCodeValied) ? 'none' : 'flex'}>

              <FormControl>
                <FormLabel htmlFor="tel">Enter your phone number, including the extension</FormLabel>
                <Input
                  id="tel"
                  type="tel"
                  placeholder="+44 7098765432"
                  defaultValue={defaultPhoneNumber}
                  disabled={isLoading}
                  onChange={(event) => setPhoneNumber(event.target.value)}
                />
              </FormControl>

              <Button onClick={sendResetCode}>Send Reset Code</Button>

              <Divider />

              <FormControl display={isForgetPasswordCodeSent ? 'block' : 'none'}>
                <FormLabel htmlFor="tel">Enter the code sent to your phone below</FormLabel>

                <HStack>
                  <PinInput otp size="lg" onComplete={onResetCodeComplete}>
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              </FormControl>

            </VStack>

            <VStack spacing={4} display={isResetCodeValied ? 'flex' : 'none'}>

              <FormControl>
                <FormLabel htmlFor="password">New Password</FormLabel>
                <Input
                  id="password"
                  type="password"
                  placeholder="******"
                  disabled={isLoading}
                  onChange={(event) => setNewPassword(event.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="password">Confirm Password</FormLabel>
                <Input
                  id="password"
                  type="password"
                  placeholder="******"
                  disabled={isLoading}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                />
              </FormControl>

              <Button onClick={updatePassword}>Update Password</Button>

            </VStack>
          </Container>
        </DrawerBody>

        <DrawerFooter>
          {/* <Button variant="outline" mr={3} */}
          {/*        onClick={() => setShowVerification(false)}> */}
          {/*  Cancel */}
          {/* </Button> */}
          {/* <Button colorScheme="teal">Verify</Button> */}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default ResetPasswordDrawer;
