import React, { useState, useEffect, useContext } from 'react'
import Card from '../Card'
import {
  VStack,
  Text,
  Heading,
  Button,
  Stack,
} from '@chakra-ui/react'
import {
  BasicPricingCard,
  PlusPricingCard,
  PremiumFeatures,
  premiumPlans, PremiumPricingCard,
} from '../PricingView'
import { AppContext } from '../../providers/AppProvider'
import { useNavigate } from 'react-router-dom'


const SuccessDisplay = ({ sessionId }) => {
  return (
    <Card>
      <VStack>
        <Heading>Subscripe to our TIER2 plan</Heading>
        <Text fontSize="xl">
          <b>£4.99 </b> a month
        </Text>
      </VStack>
    </Card>
  );
};

const Message = ({ message }) => (
  <Card>
    <p>{message}</p>
  </Card>
);


export default function ProUserCheckout() {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const {
    showErrorToast, subscribeToPremium, user,
  } = useContext(AppContext)
  const navigate = useNavigate()

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  function doSubscribeToPremium (type) {
    setIsLoading(true)
    subscribeToPremium(type).then(response => {
      const session = response?.data?.result;
      if (session?.url) {
        window.location.replace(session?.url)
      } else {
        showErrorToast({ message: "Failed to subscribe" })
      }
    }).catch(e => showErrorToast({ message: e.message }))
      .finally(() => setIsLoading(false))
  }

  if (!success && message === '') {
    return (
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={6}
        align={{ base: 'center', md: 'stretch' }}
        justify={"center"}
      >

        <BasicPricingCard>
          <Button
            marginTop={'auto !important'}
            onClick={() => doSubscribeToPremium('BASIC')}
            isDisabled={user?.subscriptionPlanName && user?.subscriptionPlanName?.contains('Basic')}
            isLoading={isLoading}
          >
            {user?.subscriptionPlanName?.contains('Basic') ? "Your current plan" : `Upgrade to Basic`}
          </Button>
        </BasicPricingCard>

        <PlusPricingCard>
          <Button
            marginTop={'auto !important'}
            onClick={() => doSubscribeToPremium('PLUS')}
            bgGradient={premiumPlans.TIER2.bgGradient}
            _hover={{
              bgGradient: premiumPlans.TIER2.bgGradient,
              opacity: 0.8
            }}
            isDisabled={user?.subscriptionPlanName && user?.subscriptionPlanName?.contains('Plus')}
            isLoading={isLoading}
          >
            {user?.subscriptionPlanName?.contains('Plus') ? "Your current plan" : `Upgrade to Plus`}
          </Button>
        </PlusPricingCard>

        <PremiumPricingCard>

          <Button
            marginTop={'auto !important'}
            onClick={() => doSubscribeToPremium('PREMIUM')}
            bgGradient={premiumPlans.TIER3.bgGradient}
            _hover={{
              bgGradient: premiumPlans.TIER3.bgGradient,
              opacity: 0.8
            }}
            isDisabled={user?.subscriptionPlanName && user?.subscriptionPlanName?.contains('Premium')}
            isLoading={isLoading}
          >
            {user?.subscriptionPlanName?.contains('Premium') ? "Your current plan" : `Upgrade to Premium`}
          </Button>
        </PremiumPricingCard>

      </Stack>
    );
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
}
