import Card from './Card'
import React, { useContext, useState } from 'react'
import {
  Flex,
  Heading, Text,
  VStack,
  Button,
} from '@chakra-ui/react'

import { AppContext } from '../providers/AppProvider'

function SignOutCard () {

  const { doSignOut } = useContext(AppContext)

  return (
    <>
      <Card>
        <VStack width={'100%'} spacing={6} align={'start'}>
          <Flex justify={'space-between'} align={'center'} width={'100%'}>
            <Heading as={'h3'} size={'md'}>Sign Out</Heading>
          </Flex>

          <Button width={'100%'} onClick={doSignOut}>Sign Out</Button>
        </VStack>
      </Card>
    </>
  )
}

export default SignOutCard
