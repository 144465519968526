import {
  Box,
  Button,
  Heading,
  VStack,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { CheckCircleIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'

function SubscriptionCancelled () {

  const navigate = useNavigate()

  return (

    <VStack textAlign="center" spacing={6}>

      <Box textAlign="center" py={10} px={6}>
        <CheckCircleIcon boxSize="50px" color="green.500"/>
        <Heading as="h2" size="xl" mt={6} mb={2}>
          Subscription Cancelled
        </Heading>
        <Text color="gray.500">
          Your subscription was cancelled.
        </Text>
      </Box>
      <Button onClick={() => navigate('/app')}>Back to my account</Button>

    </VStack>
  )
}

export default SubscriptionCancelled
